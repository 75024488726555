.badbeat {
  .game-community {
    position: initial;
    width: 200%;
    margin: 0 auto;
  }
  .bb-community {
    padding-top: 15%;
    padding-left: 2%;
    width: 50%;
    .game-community {
      width: 50%;
    }
  }
  .bb-winner, .bb-loser {
    width: 25%;
    float: left;
    text-align: center;
  }
  .bb-beaten {
    width: 6%;
    float: left;
    padding-top: 2%;
    text-align: center;
  }
  .bb-hand {
    font-weight: 200;
    font-size: 80%;
  }
  .bb-list {
    margin-left: 5%;
    width: 35%;
    float: left;
  }
}

// TODO: Pending delete after mergeing rooms - react Game Details has copy of this css
.badbeat-balance {
  color: #d7b23a
}
// END TODO