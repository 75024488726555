.blanketNotice {
  position: fixed;
  height: 100%;
  width: 60%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10001;
  text-align: center;
  font-size: 150%;
  display: block;
  padding: 20%;
  line-height: 1.2em;
}

.blanketNotice-textLink {
  color: #0066cc;
  cursor: pointer;
  padding-left: 0.2em;
  text-decoration: underline;
}

.blanketNotice-textLink:hover {
  color: #3399ff;
}

.blanketNotice-text {
  display: block;
}

@media all and (orientation: landscape) {
  #rotateNotice {
    display: none;
  }
}
