@import "../common/variables";

$bodyBackground: #333333 radial-gradient(ellipse at center, #3A3A3A 30%, #272727 90%);

// Login
$loginLogo: url('./images/logoColor.svg') center center no-repeat;
$loginLogoSize: 100%;

$lobbyTrimColor: #880000;
$miniTableBackground: radial-gradient(center, ellipse farthest-corner, rgba(118, 132, 158, 1) 4%, rgba(22, 22, 22, 1) 100%);

$cardCustomBackImg: url('./images/cardBack.svg');
$defaultAvatar: url('~@pipehat/branding-betusa/default-avatar-128x128.png');

$tableNotificationImage: url('images/icon.png');

$carpetColor: rgb(24, 27, 31);
$tableFeltColor: #444b53;
$tableHighlightTextColor: #b4b4b4;
