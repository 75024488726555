@use 'sass:math';

// How ratios work
//
// Standard emoticon sizes 76px Wide X 76px high, ratio 1:1
//  let width 76px = 100 units of width -> 100%
//  let height 76px = 100 units of height -> 100%
//  ∴therefore
//    sprite width 7987px, 7987/76 = 105.09 * 100units = 10509%
//    -> background-size: 10509% 100%
//
// odd sized emotes eg: .sml014-1 is 129px width X 76px high, ratio 1.69736842105263:1
//  let width 129px = 100 units of width -> 100%
//  let height 76px = 100 units of height -> 100%
//  ∴therefore
//    sprite width  7987px, 7987/129 = 61.9147286821705 * 100units = 6191.47286821705%
//    -> background-size: 6191.47286821705% 100%

// HOW TO MODIFY EMOT SIZE?
// Modify containerWidth to increase/decrease to adujst emot size,
// standard emot icon width will be 35% of the chatbox size,
// 50% means the emot will be 50% the size of the chatbox
$containerWidth: 35%;

// sprite total width
$spriteWidth: 7987px;

// standard sprite slice width, obscure sizes are defined in its class
$unitWidth: 76px;

// Standard emot width is 76px (unitWidth) however some are oddsized eg: 114px
// getOddWidth expresses the width as a ratio to the standard
// to see the effects insert { border: red 1px solid to } to .emoticon
@function getOddWidth($width) {
  @return math.div($width, $unitWidth) * $containerWidth;
}

// Express pixel offset as a percentage, important subtract 1 unit of width from spriteWidth
@function getOffsetAsPercent($offset, $width) {
  @return math.div($offset, ($spriteWidth - $width)) * 100%
}

@function getBackgroundSize($width) {
  @return math.div($spriteWidth, $width) * 100%;
}

@mixin generateSlice($offset, $width: $unitWidth) {
  @if $width != $unitWidth {
    width: getOddWidth($width);
    background-size: getBackgroundSize($width) 100%;
  }
  background-position: getOffsetAsPercent($offset, $width) 0%;
}


// emoticon in chat
.emoticon {
  height: 0;
  background-repeat: no-repeat;
  background-image: url('../common/images/emoticons/baseSprite.png');
  background-size: getBackgroundSize($unitWidth) 100%;
  padding-bottom: $containerWidth;
  width: $containerWidth;
}

.sml001 {
  @include generateSlice(0px);
}
.sml002 {
  @include generateSlice(76px);
}
.sml003 {
  @include generateSlice(152px);
}
.sml004-1 {
  @include generateSlice(228px);
}
.sml004-2 {
  @include generateSlice(304px);
}
.sml004-3 {
  @include generateSlice(380px);
}
.sml004-4 {
  @include generateSlice(456px);
}
.sml005-1 {
  @include generateSlice(532px);
}
.sml005-2 {
  @include generateSlice(608px);
}
.sml005-3 {
  @include generateSlice(684px);
}
.sml006-1 {
  @include generateSlice(760px);
}
.sml006-2 {
  @include generateSlice(836px);
}
.sml006-3 {
  @include generateSlice(912px);
}
.sml007 {
  @include generateSlice(988px);
}
.sml008-1 {
  @include generateSlice(1064px);
}
.sml008-2 {
  @include generateSlice(1140px);
}
.sml008-3 {
  @include generateSlice(1216px);
}
.sml009 {
  @include generateSlice(1292px);
}
.sml010 {
  @include generateSlice(1368px);
}
.sml011-1 {
  @include generateSlice(1444px);
}
.sml011-2 {
  @include generateSlice(1520px);
}
.sml011-3 {
  @include generateSlice(1596px);
}
.sml011-4 {
  @include generateSlice(1672px);
}
.sml012 {
  @include generateSlice(1748px, 104px);
}
.sml013-1 {
  @include generateSlice(1852px, 114px);
}
.sml013-2 {
  @include generateSlice(1966px, 114px);
}
.sml013-3 {
  @include generateSlice(2080px, 114px);
}
.sml014-1 {
  @include generateSlice(2194px, 129px);
}
.sml014-2 {
  @include generateSlice(2323px, 129px);
}
.sml014-3 {
  @include generateSlice(2452px, 129px);
}
.sml014-4 {
  @include generateSlice(2581px, 129px);
}
.sml014-5 {
  @include generateSlice(2710px, 129px);
}
.sml015-01 {
  @include generateSlice(2839px, 114px);
}
.sml015-02 {
  @include generateSlice(2953px, 114px);
}
.sml015-03 {
  @include generateSlice(3067px, 114px);
}
.sml015-04 {
  @include generateSlice(3181px, 114px);
}
.sml015-05 {
  @include generateSlice(3295px, 114px);
}
.sml015-06 {
  @include generateSlice(3409px, 114px);
}
.sml015-07 {
  @include generateSlice(3295px, 114px);
}
.sml015-08 {
  @include generateSlice(3523px, 114px);
}
.sml015-09 {
  @include generateSlice(3637px, 114px);
}
.sml015-10 {
  @include generateSlice(3751px, 114px);
}
.sml016-1 {
  @include generateSlice(3865px);
}
.sml016-2 {
  @include generateSlice(3941px);
}
.sml017 {
  @include generateSlice(4017px, 94px);
}
.sml018-01 {
  @include generateSlice(4111px);
}
.sml018-02 {
  @include generateSlice(4187px);
}
.sml018-03 {
  @include generateSlice(4263px);
}
.sml019-1 {
  @include generateSlice(4339px);
}
.sml019-2 {
  @include generateSlice(4415px);
}
.sml019-3 {
  @include generateSlice(4491px);
}
.sml020-1 {
  @include generateSlice(4567px);
}
.sml020-2 {
  @include generateSlice(4643px);
}
.sml020-3 {
  @include generateSlice(4719px);
}
.sml020-4 {
  @include generateSlice(4795px);
}
.sml020-5 {
  @include generateSlice(4871px);
}
.sml020-6 {
  @include generateSlice(4947px);
}
.sml021-01 {
  @include generateSlice(5023px);
}
.sml021-02 {
  @include generateSlice(5099px);
}
.sml021-03 {
  @include generateSlice(5175px);
}
.sml021-04 {
  @include generateSlice(5251px);
}
.sml022-01 {
  @include generateSlice(5327px, 134px);
}
.sml022-02 {
  @include generateSlice(5461px, 134px);
}
.sml022-03 {
  @include generateSlice(5595px, 134px);
}
.sml022-04 {
  @include generateSlice(5729px, 134px);
}
.sml022-05 {
  @include generateSlice(5863px, 134px);
}
.sml022-06 {
  @include generateSlice(5997px, 134px);
}
.sml022-07 {
  @include generateSlice(6131px, 134px);
}
.sml022-08 {
  @include generateSlice(6265px, 134px);
}
.sml022-09 {
  @include generateSlice(6399px, 134px);
}
.sml022-10 {
  @include generateSlice(5327px, 134px);
}
.sml023-1 {
  @include generateSlice(6533px, 80px);
}
.sml023-2 {
  @include generateSlice(6613px, 80px);
}
.sml023-3 {
  @include generateSlice(6693px, 80px);
}
.sml024-1 {
  @include generateSlice(6773px, 130px);
}
.sml024-2 {
  @include generateSlice(6903px, 130px);
}
.sml024-3 {
  @include generateSlice(7033px, 130px);
}
.sml024-4 {
  @include generateSlice(7163px, 130px);
}
.sml024-5 {
  @include generateSlice(7293px, 130px);
}
.sml024-6 {
  @include generateSlice(7423px, 130px);
}
.sml024-7 {
  @include generateSlice(7553px, 130px);
}
.sml025-1 {
  @include generateSlice(7683px);
}
.sml025-2 {
  @include generateSlice(7759px);
}
.sml025-3 {
  @include generateSlice(7835px);
}
.sml025-4 {
  @include generateSlice(7987px);
}

// emoticon overlay selector
.modal .select-emoticon-box {
  .title {
    padding-bottom: 0;
  }
  .content {
    padding: 0;
  }
}

.select-emoticon-wrap {
  width: 10%;
  float: left;
  padding: 5% 1%;
}

.select-emoticon-emot {
  margin: 0 auto;
  display: block;
  transform: scale(2.6, 2.6);
  z-index: 1;
}

.select-emoticon-emot-:hover {
  cursor: pointer;
  z-index: 100;
  transform: scale(4, 4);
}

// hack to resolve WEB-1160 ("hi" appears to have a star in hand from "punch" beside it)
// JHM I just blew away the star from the sprite sheet. Looks fine. Gif works. Erased from the matrix
.select-emoticon-wrap-sml013-1 {
  .sml013-1 {
    margin-right: 8%;
  }
}

// the bleak looking end one that is well off centre
.select-emoticon-wrap-sml024-1 {
  .sml024-1 {
    margin-right: -10%;
  }
}

// cigar joe
.select-emoticon-wrap-sml022-01 {
  .sml022-01 {
    margin-right: -16%;
  }
}

// Beer bottle guy - make more centre
.select-emoticon-wrap-sml017 {
  .sml017 {
    margin-right: 20%;
  }
}

// thumbs up
.select-emoticon-wrap-sml012 {
  .sml012 {
    margin-right: 16%;
  }
}
