// buy-in / rebuy

.bettingControlsModal {
  .box {
    width: 80%;
    background: $modalSliderBackground;
    color: $modalSliderTextColor;
    border-color: $modalSliderBorderColor;
  }

  .label {
    font-size: 120% !important;
    .preview {
      display: none;
      margin-left: 20px;
    }
  }

  .content {
    width: 100%;
    padding: 0 !important;

    .presets {
      position: relative;
      width: 100%;
      padding: 2% 0;
      .button {
        text-overflow: clip;
      }
    }

    .details {
      color: #BBB;
      font-size: 85%;
      padding: 2% 4% 3%;
      span {
        color: white;
      }
    }

    .details {
      width: 42%;
      float: left;
      .balance {
        padding: 5px 0;
      }
    }
  }
}

// deprecated legacy coffee+jquery stepper. Replaced with a css module for a React Stepper component
.stepper {
  width: max-content;
  background: whitesmoke;
  color: black;
  font-size: 240%;
  text-align: center;
  margin: 0 auto;
  display: block;
  border-radius: 0.2em;

  .stepper-less, .stepper-more {
    display: inline-block;
    width: 1em;
    padding: 0.1em;
    vertical-align: middle;
    cursor: pointer;
  }

  .stepper-amount {
    background: black;
    color: whitesmoke;
    display: inline-block;
    width: 6em;
    text-align: center;
    border: 1px solid whitesmoke;
  }
}

.buyin-cashier-link {
  margin-left: 10px;
  font-size: 90%;
  opacity: 0.8;
  cursor: pointer;
}

// not sure where else to put this. doesn't really warrant it's own file
.rebuy-payment-options, .entry-payment-options {
  width: 40%;
  margin-left: 30%;
  font-size: 120%;
  border: 1px solid white;
  margin-bottom: 30px;
  margin-right: 15px;
}

.tournamentReentry .title {
  padding-bottom: 2% !important; // decrease default to make room
}

.pending-rebuy {
  color: red;
  padding: 0 4% 4%;
}

@media all and (max-device-width: 480px) {
  .modal {
    .box.bettingControlsModal {
      padding: 1%;
      .title {
        padding-bottom: 3%;
      }
      button, .button {
        font-size: 90%;
      }
    }
  }
}
