.lobbyTabBar {
  background: black;
  z-index: 10;
  flex-shrink: 0;
  // jQuery.toggle() on flex items switches them back to block
  &.hidden {
    display: none;
  }
  .pane.hidden {
    display: none;
  }

  // gameList has child panes, quickSeatTabBar does not
  .pane, &.quickSeatTabBar {
    display: flex;
  }
}

.lobbyTab {
  flex: 1;
  background: $lobbyHeaderBackground;
  cursor: pointer;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #AAA;
  font-size: 90%;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 3px solid rgba(255, 255, 255, 0.1);
  min-width: 100px;
  .top {
    font-size: 70%;
    color: #999;
  }
}

.lobbyTab-active {
  cursor: default;
  background: $tabHoverBackground;
  border-bottom-color: $lobbyTabSelectedTrim;
  color: white;
}

.pane__ringGames {
  .lobbyTab {
    min-width: 120px;
  }
}
