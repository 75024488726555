.hand-history-game-list {
  float: left;
  width: 18%;
  padding: 1%;
  background: rgba(100, 100, 100, 0.15);
  height: 400px;
  .active {
    font-weight: bold;
  }
}

.hand-history-display-area {
  float: left;
  width: 60%;
  margin-left: 5%;
  padding: 2%;
  height: 400px;
  overflow: scroll;
  background: rgba(100, 100, 100, 0.15);
  .current-state {
    font-weight: bold;
  }
  .event {
    color: #AAA;
  }
}

.hand-history-text-area {
  float: left;
  width: 60%;
  margin-left: 5%;
  padding: 2%;
  height: 400px;
  display: none;
}

.copy-hand-history-link {
  position: absolute;
  cursor: pointer;
  top: -35%;
  right: 0;
  font-size: 80%;
  z-index: 1;
}

.box.hand-history-modal {
  .title {
    padding-bottom: 3%;
  }
  .content {
    position: relative;
  }
}
