.preview-bar {
  background-color: #181a1e;
  display: flex;
  flex-direction: column;
  z-index: 1; // appear above the tiling tables
}

.preview-bar-carousel {
  overflow: auto;
  white-space: nowrap;
  display: flex;
  flex: 6;
  flex-direction: column;
  scroll-behavior: smooth;
}

.preview-table {
  min-height: 3em;
  min-width: 6em;
  position: relative;
  margin: 0;
  padding: 5px 10px 0;
  background: rgb(30, 30, 30);
  opacity: 0.8;
  box-sizing: border-box;
  &.fold .cards {

    opacity: 0.4;
  }

  .felt {
    border-radius: 8em;
    position: absolute;
    left: 10%;
    right: 10%;
    top: 15%;
    bottom: 15%;
    background-color: #4e4e4e;
    overflow: hidden;
  }

  &.current {
    opacity: 1;
    background-color: #464648;

    .simple-card {
      opacity: 1;
    }

    .felt {
      background: #69696f;
    }
  }

  .cards {
    pointer-events: none;
  }

  .simple-card {
    width: 30%;
    position: absolute;
    left: 18%;
    top: 1.5em;
    bottom: 0;
    font-size: 80%;
    opacity: .5;
    border-radius: .3em .3em 0 0;
    &:last-child {
      left: 52%;
    }
  }
  .cards-4 .simple-card {
    width: 24%;
    &:nth-child(1) {
      left: 0%;
    }
    &:nth-child(2) {
      left: 25%;
    }
    &:nth-child(3) {
      left: 50%;
    }
    &:last-child {
      left: 75%;
    }
  }
  .timer {
    height: 100%;
    // width: 0;
    // background-color: red;
  }
  &.allIn .timer  {
    background-color: $allIn;
  }
  &.bet .timer  {
    background-color: $bet;
  }
  &.check .timer  {
    background-color: $check;
  }
  &.raise .timer  {
    background-color: $raise;
  }
  &.call .timer  {
    background-color: $call;
  }
  &.fold .timer  {
    background-color: $fold;
  }
}

.preview-bar-tab {
  min-height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .5;

  &.lobby {
    flex: 2;
    min-width: 3em;
    flex-direction: column;

    .fa {
      font-size: 1.7em;
    }

    .label {
      font-size: .8em;
    }
  }

  &.indicator {
    flex: 1;
    min-width: 3em;
    z-index: 1;
    color: #4f4f52;
    transition: box-shadow .3s ease-in-out;

    .fa {
      font-size: 2em;
    }
  }

  &.scrolled {
    color: inherit;
  }

  &.previous.scrolled {
    box-shadow: 0 .2em .2em black;
  }

  &.next.scrolled {
    box-shadow: 0 -0.2em .2em black;
  }
}

@media all and (min-height: 550px) {
  .preview-bar {
    min-height: 0;
    flex-direction: row;

    > .preview-bar-carousel {
      flex-direction: row;

      > .preview-table {
        width: 7em;
      }
    }

    > .preview-bar-tab {
      &.lobby {
        max-width: 6em;
        flex-grow: 1.5;
      }

      &.indicator {
        max-width: 4em;

        &.previous.scrolled {
          box-shadow: .2em 0 .2em black;
        }

        &.next.scrolled {
          box-shadow: -0.2em 0 .2em black;
        }

        .fa {
          transform: rotate(270deg);
        }
      }
    }
  }
}
