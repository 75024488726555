// Main Component Sprite Sheet, base class
// Base declarations for X:Y sprite co-ords only, everything else is declared in some*_view.scss
// customisation: *if and only *if custom sprites are required, replace this entire file maintaining class names
// important, co-ords expressed as a % ratio are scaleable, px co-ords are fixed size

.sprite {
  background-image: $componentsSpriteUrl;
}

.sprite-playersOnline {
  background-position: -306px 46px;
  height: 9px;
  width: 9px;
}

.sprite-downArrow {
  background-position: -292px 46px;
  height: 9px;
  width: 9px;
}

.sprite-iconChat {
  background-size: 580%;
  background-position: 78.7% 0%;
}

.sprite-iconSmile {
  background-size: 580%;
  background-position: 99.5% 0%;
}

.sprite-timebankOn {
  background-size: 470%;
  background-position: 55.7% 0%;
}

.sprite-timebankOff {
  background-size: 470%;
  background-position: 28.8% 0%;
}

.sprite-dealer {
  background-size: 1400%;
  background-position: 24.5% 100%;
  width: 1.75em;
  height: 1.75em;
}

.sprite-seat {
  background-size: 450%;
  background-position: 0% 0%;
}
