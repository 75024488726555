@use 'sass:math';

// -- vars
$bg-color: #34495e;
$default-size: 1em;
$label-font-size: math.div($default-size, 3);
$label-font-size-redo: $default-size * 3;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin draw-progress($progress) {
  .pie {
    .half-circle {
      border-color: #888;
    }

    .left-side {
      transform: rotate($progress * 3.6deg);
    }

    @if $progress <= 50 {
      .right-side {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        transform: rotate(180deg);
      }
    }
  }
}

// -- selectors
.knob-set-size {
  font-size: 2em;
}

.knob-set-size-mobile {
  font-size: 1.5em;
}

.knob-container * {
  box-sizing: border-box;
}

.knob-pie-wrapper {
  @include size($default-size, $default-size);
  float: left;
  margin-left: 5px;
  position: relative;

  .pie {
    @include size(100%, 100%);
    clip: rect(0, $default-size, $default-size, math.div($default-size, 2));
    left: 0;
    position: absolute;
    top: 0;

    .half-circle {
      @include size(100%, 100%);
      border: math.div($default-size, 6) solid;
      border-radius: 50%;
      clip: rect(0, math.div($default-size, 2), $default-size, 0);
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .label {
    background: $bg-color;
    border-radius: 50%;
    cursor: default;
    display: block;
    font-size: $label-font-size;
    position: absolute;
    text-align: center;
    line-height: 1em;
    top: 1em;
    bottom: 1em;
    left: math.div($label-font-size-redo, 10);
    right: math.div($label-font-size-redo, 10);
  }

  .shadow {
    @include size(100%, 100%);
    border: math.div($default-size, 6) solid #ddd;
    border-radius: 50%;
  }

  &.progress-style {
    .label {
      background: none;
      color: #888;
    }
  }

  &.progress-0 { @include draw-progress(0); }
  &.progress-1 { @include draw-progress(1); }
  &.progress-2 { @include draw-progress(2); }
  &.progress-3 { @include draw-progress(3); }
  &.progress-4 { @include draw-progress(4); }
  &.progress-5 { @include draw-progress(5); }
  &.progress-6 { @include draw-progress(6); }
  &.progress-7 { @include draw-progress(7); }
  &.progress-8 { @include draw-progress(8); }
  &.progress-9 { @include draw-progress(9); }
  &.progress-10 { @include draw-progress(10); }
  &.progress-11 { @include draw-progress(11); }
  &.progress-12 { @include draw-progress(12); }
  &.progress-13 { @include draw-progress(13); }
  &.progress-14 { @include draw-progress(14); }
  &.progress-15 { @include draw-progress(15); }
  &.progress-16 { @include draw-progress(16); }
  &.progress-17 { @include draw-progress(17); }
  &.progress-18 { @include draw-progress(18); }
  &.progress-19 { @include draw-progress(19); }
  &.progress-20 { @include draw-progress(20); }
  &.progress-21 { @include draw-progress(21); }
  &.progress-22 { @include draw-progress(22); }
  &.progress-23 { @include draw-progress(23); }
  &.progress-24 { @include draw-progress(24); }
  &.progress-25 { @include draw-progress(25); }
  &.progress-26 { @include draw-progress(26); }
  &.progress-27 { @include draw-progress(27); }
  &.progress-28 { @include draw-progress(28); }
  &.progress-29 { @include draw-progress(29); }
  &.progress-30 { @include draw-progress(30); }
  &.progress-31 { @include draw-progress(31); }
  &.progress-32 { @include draw-progress(32); }
  &.progress-33 { @include draw-progress(33); }
  &.progress-34 { @include draw-progress(34); }
  &.progress-35 { @include draw-progress(35); }
  &.progress-36 { @include draw-progress(36); }
  &.progress-37 { @include draw-progress(37); }
  &.progress-38 { @include draw-progress(38); }
  &.progress-39 { @include draw-progress(39); }
  &.progress-40 { @include draw-progress(40); }
  &.progress-41 { @include draw-progress(41); }
  &.progress-42 { @include draw-progress(42); }
  &.progress-43 { @include draw-progress(43); }
  &.progress-44 { @include draw-progress(44); }
  &.progress-45 { @include draw-progress(45); }
  &.progress-46 { @include draw-progress(46); }
  &.progress-47 { @include draw-progress(47); }
  &.progress-48 { @include draw-progress(48); }
  &.progress-49 { @include draw-progress(49); }
  &.progress-50 { @include draw-progress(50); }
  &.progress-51 { @include draw-progress(51); }
  &.progress-52 { @include draw-progress(52); }
  &.progress-53 { @include draw-progress(53); }
  &.progress-54 { @include draw-progress(54); }
  &.progress-55 { @include draw-progress(55); }
  &.progress-56 { @include draw-progress(56); }
  &.progress-57 { @include draw-progress(57); }
  &.progress-58 { @include draw-progress(58); }
  &.progress-59 { @include draw-progress(59); }
  &.progress-60 { @include draw-progress(60); }
  &.progress-61 { @include draw-progress(61); }
  &.progress-62 { @include draw-progress(62); }
  &.progress-63 { @include draw-progress(63); }
  &.progress-64 { @include draw-progress(64); }
  &.progress-65 { @include draw-progress(65); }
  &.progress-66 { @include draw-progress(66); }
  &.progress-67 { @include draw-progress(67); }
  &.progress-68 { @include draw-progress(68); }
  &.progress-69 { @include draw-progress(69); }
  &.progress-70 { @include draw-progress(70); }
  &.progress-71 { @include draw-progress(71); }
  &.progress-72 { @include draw-progress(72); }
  &.progress-73 { @include draw-progress(73); }
  &.progress-74 { @include draw-progress(74); }
  &.progress-75 { @include draw-progress(75); }
  &.progress-76 { @include draw-progress(76); }
  &.progress-77 { @include draw-progress(77); }
  &.progress-78 { @include draw-progress(78); }
  &.progress-79 { @include draw-progress(79); }
  &.progress-80 { @include draw-progress(80); }
  &.progress-81 { @include draw-progress(81); }
  &.progress-82 { @include draw-progress(82); }
  &.progress-83 { @include draw-progress(83); }
  &.progress-84 { @include draw-progress(84); }
  &.progress-85 { @include draw-progress(85); }
  &.progress-86 { @include draw-progress(86); }
  &.progress-87 { @include draw-progress(87); }
  &.progress-88 { @include draw-progress(88); }
  &.progress-89 { @include draw-progress(89); }
  &.progress-90 { @include draw-progress(90); }
  &.progress-91 { @include draw-progress(91); }
  &.progress-92 { @include draw-progress(92); }
  &.progress-93 { @include draw-progress(93); }
  &.progress-94 { @include draw-progress(94); }
  &.progress-95 { @include draw-progress(95); }
  &.progress-96 { @include draw-progress(96); }
  &.progress-97 { @include draw-progress(97); }
  &.progress-98 { @include draw-progress(98); }
  &.progress-99 { @include draw-progress(99); }
  &.progress-100 { @include draw-progress(100); }
}
