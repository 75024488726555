@use 'sass:math';

// Markup for a card: toggle .facedown on the .card to flip it over
// <div class="card">
//   <div class="front h4"></div>
//   <div class="back"></div>
// </div>

// Uses Standard java client card sprite sheet 2100x1350 png
// cardWidth, % of screen width, adjust this value to scale card up/down
// cut up sprite as a %, where 1 card is x% & y% ratio of the sprite sheet W & H

$cardWidth: 4%;
$x: 7.6923076923%; // 100 / 13 %
$y: 20%;           // 100 / 5 %


// Function to generate individual card classes
//  generateCards()                   -> makes standard card set
//  generateCards('fourColorDeck')    -> makes 4 color card set
@mixin generateCards($type) {
  $ranks:   2 3 4 5 6 7 8 9 t j q k a;
  $suitIndex: 0;
  $suits: c d h s;

  @if $type == 'fourColorDeck' {
    $suitIndex: 2;
    $suits: h s c d;
  }

  @each $suit in $suits {
    $rankIndex: 0;
    @each $rank in $ranks {
      .card .front.#{$suit}#{$rank} {
        background-position: math.div(ceil($x * $rankIndex * 100), 100) $y * $suitIndex;
      }
      $rankIndex: $rankIndex + (1);
    }
    $suitIndex: $suitIndex + (1);
  }
}

// Run function declared above
@include generateCards('');

.fourColorDeck {
  @include generateCards('fourColorDeck');
}


// Common card styles. Default card is error (x=13, y=2)
.card {
  // border-bottom: 1px solid red
  // border-top: 1px solid red
  position: relative;
  background-image: inherit;
  background-size: 1400%;
  display: inline-block;
  line-height: 0px;
  width: 20%;
  height: 0;
  padding-bottom: 29.8%; //overrides at bottom of file for iphone and ipad
  transition: all 0.1s ease-in-out;

  .front, .back {
    position: absolute;
    background-image: $cardSpriteSheet;
    width: 100%;
    height: 100%;
    background-size: inherit;
    transition: all 0.15s ease-in-out;
    // Rules for 3D acceleration 15/03/2013
    // Not essential, but speed helps and techniques/support change, so keep these up to date
    transform-style: preserve-3d;
    transform: translateZ(0);
    perspective: 1000;
    backface-visibility: hidden;
  }

  .front {
    transform: rotateY(0deg);
    background-position: $x * 13, $y * 2; // the ERROR card
  }

  .back {
    transform: rotateY(-180deg);
    background: $cardCustomBackImg no-repeat $cardCustomBackPosition/$cardCustomBackSize;
  }

  .win {
    margin-top: -10%;
  }
}

.card.facedown {

  .front {
    transform: rotateY(180deg);
  }

  .back {
    transform: rotateY(0deg);
  }
}

// Animations
@-webkit-keyframes cardSlide {
  100% {
    left: 0;
  }
}
@-moz-keyframes cardSlide {
  100% {
    left: 0;
  }
}
@-ms-keyframes cardSlide {
  100% {
    left: 0;
  }
}
@-o-keyframes cardSlide {
  100% {
    left: 0;
  }
}
@keyframes cardSlide {
  100% {
    left: 0;
  }
}

// // Playerbox
// only flips when .show & card value class is appended
.player {
  perspective: 0;

  .cards {
    position: absolute;
    margin: 0 auto 2px auto;
    z-index: -1;
    width: 150%;
    left: -15%;
    overflow: hidden;
    bottom: 100%;
    height: 95%;
    .card {
      margin-top: 5%;
      margin-left: -10%;
    }
  }

  &.clientPlayer {
    .cards {
      left: -25%;
      .card {
        margin-left: 0%;
      }
    }
  }
}

// // Community cards
// on flop, slide the second and third cards in from the left
.game-community.flop {
  .card:nth-child(2) {
    left: -($cardWidth);
    animation: cardSlide 0.2s 1 linear forwards;
    // Apprently by forcing a 3d translate it invokes the gpu on ios devices
    transform: translateZ(0);
  }
  .card:nth-child(3) {
    left: -($cardWidth * 2);
    animation: cardSlide 0.3s 1 linear forwards;
    transform: translateZ(0);
  }
}

// TEMPORARY tweak position/size of client cards for Omaha (4 hole cards)
.type-O, .type-OHL {
  .player.clientPlayer .card {
    margin-left: -6%;
    &:first-child {
      margin-left: 1%;
    }
  }
}

// iPhone < 5:
@media screen and (device-aspect-ratio: 2 / 3) and (-webkit-min-device-pixel-ratio: 2) {
  .card {
    padding-bottom: 29.7%;
  }
}

// iPhone 5:
@media screen and (device-aspect-ratio: 40 / 71) and (-webkit-min-device-pixel-ratio: 2) {
  .card {
    padding-bottom: 30%;
  }
}

// iPad:
@media screen and (device-aspect-ratio: 3 / 4) and (-webkit-min-device-pixel-ratio: 2) {
  .card {
    padding-bottom: 30%;
  }
}

// iPad 1:
@media screen and (-webkit-max-device-pixel-ratio: 1) {
  .card {
    padding-bottom: 29.7%;
  }
}
