
.dynamic-logo {
  background: $loginLogo;
  background-size: $loginLogoSize;
  height: 7em;
  width: 100%;
  transition: height .6s ease;
  align-self: center;

  &.medium {
    @media (min-height: 550px) {
      background-size: $loginLogoSize * 1.5;
      height: 10.5em;
    }
  }
}


// HACK: IE11 hack to fix background sizes being oddly tiny
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dynamic-logo {
    background-size: $loginLogoSize * 4;

    &.medium {
      @media (min-height: 550px) {
        background-size: $loginLogoSize * 1.5 * 3.6;
        height: 10.5em;
      }
    }
  }
}
