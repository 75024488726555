#players {
  width: 51em;
  height: 27em;
  margin: 2em auto;
  position: relative;
}

.seats {
  position: relative;
  width: 51em;
  height: 27em;
  margin: 2em auto;
}

// iPhone/iPod 5 bump down:
@media (device-height: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  #players {
    top: 2%;
  }
}

.player {
  position: absolute;
  width: 10em;
  margin-left: -10%;
  margin-top: -5.2em;
  background: rgba(0, 0, 0, 0.7);
  border: 2px solid #2F2F2E;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 1);
  text-align: center;
  line-height: auto;
  font-size: 100%;
  z-index: 110;

  .aspect {
    // playerbox has fixed aspect ratio 1:3
    margin-top: 33%;
  }

  .left {
    position: absolute;
    top: 10%;
    left: 0;
    right: 70.3%;
    bottom: 0;
    border-bottom-left-radius: 5%;
  }

  .right {
    position: absolute;
    top: 10%;
    left: 29.7%;
    right: 0%;
    bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
  }

  .alias, .balance, .status, .label, .allInWinPct {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 50%;
    width: 100%;
    display: block;
  }

  .alias {
    font-size: 90%;
    height: 45%;
    white-space: nowrap;
    color: #B1B1B7;
    margin-top: 4%;
  }

  .balance {
    font-size: 90%;
    height: 55%;
  }

  .allInWinPct {
    top: 50%;
    font-size: 100%;
  }

  .allInWinPct.winning {
    color: green;
  }

  .allInWinPct.losing {
    color: red;
  }

  .status, .label {
    position: absolute;
    height: 100%;
    font-size: 90%;
  }

  .label {
    padding-top: 12%;
  }

  .statusBar, .timer {
    position: absolute;
    top: 0px;
    height: 10%;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.3);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: rgb(47, 47, 47);
    width: 100%;
  }
}

.player-accolades {
  width: 25%; // actually doesn't matter that much due to "contain" (height will dictate size)
  height: 37%;
  position: absolute;
  bottom: 10%;
  left: 35%;
  background-size: contain;
  background-repeat: no-repeat;
}

// Specific player selectors
.player.allIn {
  .label {
    color: $allIn;
  }
  .statusBar {
    background-color: $allIn;
  }
}

.player.bet {
  .label {
    color: $bet;
  }
  .statusBar {
    background-color: $bet;
  }
}

.player.raise {
  .label {
    color: $bet;
  }
  .statusBar {
    background-color: $bet;
  }
}

.player.call {
  .label {
    color: $call;
  }
  .statusBar {
    background-color: $call;
  }
}

.player.check {
  .label {
    color: $check;
  }
  .statusBar {
    background-color: $check;
  }
}

.player.fold {
  .label {
    color: $fold;
  }
  .statusBar {
    background-color: $fold;
  }
  // The following odd styles are to ensure fold-and-show and show-on-hover work correctly
  .card.show {
    display: inline-block;
  }
  &.clientPlayer {
    .card {
      display: none;
    }
    &.peek {
      .card {
        opacity: 0.5;
        display: inline-block;
      }
    }
  }
}

.player.sittingOut {
  opacity: 0.5;
  .statusBar {
    background-color: $sitout;
  }
}

.player.empty {
  display: block;
  margin-top: -4.1em;
  margin-left: -4.5em;
  height: auto !important;
  width: 15%;

  .button {
    font-size: 70%;
    font-weight: normal;
    padding: 5% 10%;
    height: 8%;
    width: 50%;
    float: right;
    border-radius: 0.2em !important;
  }

  .seat {
    margin-top: -5%; //-20%
    position: absolute;
    left: 0px;
    width: 30%;
    padding-top: 30%;
    height: 0px;
  }
}

.player.currentPlayer {
  border: 2px white ridge;
  .statusBar {
    background-color: white !important;
    box-shadow: inset 2px 1px 4px rgba(0, 0, 0, 0.3);
  }

  .timer {
    z-index: 2;
    width: 0%;
    background-color: $awaitingAction;
    transform: translate3d(0, 0, 0);
  }
}

.avatar {
  width: 100%;
  height: 100%;
  float: left;
  border-bottom-left-radius: 3px;
  background: $defaultAvatar;
  background-size: $defaultAvatarSize;
  background-position: center;
  background-repeat: no-repeat;
}

@keyframes timer {
  0% {
    background-color: $awaitingAction;
    width: 100%;
  }
  5% {
    background-color: $awaitingAction;
    border-top-right-radius: 0px;
  }
  100% {
    background-color: $awaitingAction;
    width: 0%;
  }
}

@keyframes blink {
  0% {
    background-color: $currentPlayer;
    width: 100%;
  }
  50% {
    background-color: $awaitingAction;
    width: 100%;
  }
  100% {
    background-color: $currentPlayer;
    width: 100%;
  }
}
