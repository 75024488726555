.faq {
  .container {
    color: $settingsRowText;
    background-color: white;
    padding: 1em;
    margin: 0 1em 1em;
  }
}

.support {
  .fancyList {
    margin: 0 1em 1em;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top-width: 0;

    label.iconLink {
      span:last-child {
        position: relative;
        bottom: 10px;
        left: 10px;
      }
    }
  }
}

.poker-rules {
  box-sizing: border-box;

  .container {
    padding-bottom: 1em;

    label {
      margin: 0 1em;

      &:not(.heading) {
        display: none;
      }
    }
  }
}

.poker-rules .breadcrumb {
  margin: 1em;
}

.faq .breadcrumb {
  margin-bottom: 2em;
}

.breadcrumb {
  color: #222;
  font-size: .9em;
}

.support, .faq {
  .format-message {
    margin-bottom: 0;
  }
}
