.sideMenu {
  background: $sideMenuBackground;
  color: #BBB;
  width: 16em;
  z-index: 9999;
  transition: transform 0.15s ease;
  grid-area: 2 / 1;
  overflow: scroll;
  transform: translateX(0);
  box-shadow: 6px 6px 5px rgba(0, 0, 0, 0.2);

  a {
    color: #BBB;
  }
  .hidden {
    display: none;
  }
  &.active {
    transform: translateX(0);
    box-shadow: 6px 6px 5px rgba(0, 0, 0, 0.2);
  }

  label {
    display: block;
    padding: 10px 20px;
    cursor: pointer;
    &:not(.css-checkbox-label):active {
      background: rgba(255, 255, 255, 0.05);
    }
    &:not(.css-checkbox-label):hover {
      background: rgba(20, 20, 20, 0.2);
    }
    input {
      margin-right: 2px;
      position: relative;
      bottom: 2px;
      right: 8px;
    }
    &.external-casino-link {
      display: none;
    }
    &.menu-checkbox-wrapper {
      display: block;
      padding-left: 14px;
      &.hidden {
        display: none;
      }
      .css-checkbox-label {
        padding: 0 28px !important;
      }
    }
  }

  .heading {
    font-size: 90%;
    padding: 10px 20px 5px 7px;
    margin: 5px;
    border-bottom: 1px solid #555;
    text-transform: uppercase;
    color: #888;
  }

  .iconLink {
    padding: 5px 10px;
    span:not(.icon):not(.fa) {
      position: relative;
      bottom: 11px;
      left: 6px;
    }
    .fa {
      width: 34px;
      height: 34px;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
    }
    .nudge {
      top: -4px;
    }
  }
}

.sideMenu.hidden {
  display: none;
}

.format-mobile .sideMenu {
  position: absolute;
  height: 100%;
  z-index: 10002;
}

body.update-available .update-link .fa {
  color: limegreen;
}

body.update-requested .update-link,
body.update-requested .update-link .fa {
  color: #666;
}
