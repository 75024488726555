// mini table on lobby list

.miniSeats {
  div {
    position: absolute;
    top: 25%;
    left: 25%;
    background: $lobbyTrimColor; // fallback for ff - autoprefixer doesn't churn out usable gradient for some reason
    background: $miniTableBackground;
    width: 50%;
    height: 50%;
    border-radius: 7px;
  }

  span {
    position: absolute;
    display: block;
    border-radius: 3px;
    width: 4px;
    height: 4px;
    margin-left: -3px;
    margin-top: -3px;
    border: 1px solid $miniSeatsBackground;
  }
  span.seated {
    background-color: $miniSeatsBackground;
  }
}

.miniSeats.seats_2 {
  span:nth-of-type(1) {
    top: 50%;
    left: 18%;
  }
  span:nth-of-type(2) {
    top: 50%;
    left: 82%;
  }
}

.miniSeats.seats_3 {
  span:nth-of-type(1) {
    top: 12%;
    left: 40%;
  }
  span:nth-of-type(2) {
    top: 12%;
    left: 60%;
  }
  span:nth-of-type(3) {
    top: 90%;
    left: 50%;
  }
}

.miniSeats.seats_4 {
  span:nth-of-type(1) {
    top: 12%;
    left: 40%;
  }
  span:nth-of-type(2) {
    top: 12%;
    left: 60%;
  }
  span:nth-of-type(3) {
    top: 90%;
    left: 60%;
  }
  span:nth-of-type(4) {
    top: 90%;
    left: 40%;
  }
}

.miniSeats.seats_5 {
  span:nth-of-type(1) {
    top: 50%;
    left: 18%;
  }
  span:nth-of-type(2) {
    top: 12%;
    left: 40%;
  }
  span:nth-of-type(3) {
    top: 12%;
    left: 60%;
  }
  span:nth-of-type(4) {
    top: 50%;
    left: 82%;
  }
  span:nth-of-type(5) {
    top: 90%;
    left: 50%;
  }
}

.miniSeats.seats_6 {
  span:nth-of-type(1) {
    top: 50%;
    left: 18%;
  }
  span:nth-of-type(2) {
    top: 12%;
    left: 40%;
  }
  span:nth-of-type(3) {
    top: 12%;
    left: 60%;
  }
  span:nth-of-type(4) {
    top: 50%;
    left: 82%;
  }
  span:nth-of-type(5) {
    top: 90%;
    left: 60%;
  }
  span:nth-of-type(6) {
    top: 90%;
    left: 40%;
  }
}

.miniSeats.seats_8 {
  span:nth-of-type(1) {
    top: 32%;
    left: 20%;
  }
  span:nth-of-type(2) {
    top: 12%;
    left: 40%;
  }
  span:nth-of-type(3) {
    top: 12%;
    left: 60%;
  }
  span:nth-of-type(4) {
    top: 32%;
    left: 80%;
  }
  span:nth-of-type(5) {
    top: 72%;
    left: 80%;
  }
  span:nth-of-type(6) {
    top: 90%;
    left: 60%;
  }
  span:nth-of-type(7) {
    top: 90%;
    left: 40%;
  }
  span:nth-of-type(8) {
    top: 72%;
    left: 20%;
  }
}

.miniSeats.seats_9 {
  span:nth-of-type(1) {
    top: 32%;
    left: 20%;
  }
  span:nth-of-type(2) {
    top: 12%;
    left: 40%;
  }
  span:nth-of-type(3) {
    top: 12%;
    left: 60%;
  }
  span:nth-of-type(4) {
    top: 32%;
    left: 80%;
  }
  span:nth-of-type(5) {
    top: 72%;
    left: 80%;
  }
  span:nth-of-type(6) {
    top: 90%;
    left: 65%;
  }
  span:nth-of-type(7) {
    top: 90%;
    left: 50%;
  }
  span:nth-of-type(8) {
    top: 90%;
    left: 35%;
  }
  span:nth-of-type(9) {
    top: 72%;
    left: 20%;
  }
}

.miniSeats.seats_10 {
  span:nth-of-type(1) {
    top: 32%;
    left: 20%;
  }
  span:nth-of-type(2) {
    top: 12%;
    left: 35%;
  }
  span:nth-of-type(3) {
    top: 12%;
    left: 50%;
  }
  span:nth-of-type(4) {
    top: 12%;
    left: 65%;
  }
  span:nth-of-type(5) {
    top: 32%;
    left: 80%;
  }
  span:nth-of-type(6) {
    top: 72%;
    left: 80%;
  }
  span:nth-of-type(7) {
    top: 90%;
    left: 65%;
  }
  span:nth-of-type(8) {
    top: 90%;
    left: 50%;
  }
  span:nth-of-type(9) {
    top: 90%;
    left: 35%;
  }
  span:nth-of-type(10) {
    top: 72%;
    left: 20%;
  }
}

.mini-seats-container {
  position: relative;
  width: 60px;
  height: 30px;
}
