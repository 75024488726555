#auth {
  * {
    box-sizing: border-box;
  }
}

.loginLogo {
  background: $loginLogo;
  background-size: $loginLogoSize;
  max-width: 30em;
  height: 7em;
  margin: 2em auto;
}

// HACK: IE11 hack to fix background sizes being oddly tiny
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .loginLogo {
    background-size: $loginLogoSize * 4;
  }
}

input {
  filter: none; // firefox: override autofill yellow font color

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #FFF !important; // chrome: override autofill font color
  }
}

#loginForm {
  display: none;
  width: 100%;
  margin-top: 2em;

  .login-input {
    margin: .6em 0;
    padding: .8em 0;
    background: transparent;
    border-bottom: 1px solid rgba(254, 255, 255, .33);
    outline: none;
    color: #fff;
    border-radius: 0;

    &::placeholder {
      color: rgba(255,255,255, 0.25);
    }

    &:-ms-input-placeholder {
      color: rgba(255,255,255, 0.25);
    }
  }

  .rememberUsername-label {
    font-size: .9em;
    padding-left: 1.6em;
    margin: 2.4em 0 3.2em;
  }

  .links {
    display: flex;
    flex-direction: column;

    > a {
      margin: .35em auto;
      padding: .65em;
    }
  }
}

.button-cta {
  display: block;
  font-size: 1.2em;
  width: 100%;
  max-width: 15em;
  margin: 2.4em auto;
  background: $buttonCtaBackground;
  border-radius: .3em;
  text-transform: uppercase;

  &:hover {
    background: darken($buttonCtaBackground, 10%);
  }
}

.switch-format {
  font-weight: bold;
}

.electron .switch-format {
  display: none;
}

.problem-signing-in,
.report-a-problem,
.switch-format {
  display: block;
  text-align: center;
  margin: 1em;
  font-size: 90%;
}
