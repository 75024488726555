// Standard button widget base

.button {
  color: $buttonTextColor;
  border-radius: $buttonBorderRadius;
  background-clip: padding-box;
  background: $buttonBackground;
  background-color: $buttonColor;
  border: 0 none;
  cursor: pointer;
  // font-weight: normal
  outline: 0 none;
  overflow: hidden;
  padding: 0.6em 1em;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: baseline;
  white-space: nowrap;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.button:hover {
  background-color: $buttonHoverColor;
  .button-icon {
    opacity: 1;
  }
}

.button:active {
  background-color: darken($buttonHoverColor, 10);
}

.button-disabled {
  background-color: #363532 !important;
  color: gray !important;
  opacity: 0.8;
  &:hover {
    background-color: #363532 !important;
    color: gray !important;
    opacity: 0.8;
  }
}

.button-round {
  height: 100%;
  width: 100%;
  padding: 0;
  cursor: pointer;
  margin: 0;
  background: radial-gradient(center, 50% 110%, ellipse farthest-corner, rgba(255, 255, 255, 0) 99%, rgba(255, 255, 255, 0.2) 99%, rgba(255, 255, 255, 0.2) 100%), radial-gradient(center, ellipse farthest-corner, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.2) 60%, rgba(255, 255, 255, 0.4) 75%, rgba(255, 255, 255, 1) 100%), linear-gradient(180deg, #4dc7f2 0%, #0a88c7 70%);
  border-radius: 50%;
  font-weight: bold;
  line-height: 100%;
  font-family: 'serif';
  text-align: center;
  display: block;
}

.button-round:hover {
  background: linear-gradient(180deg, rgb(128, 158, 46) 0%, rgb(187, 206, 98) 42%, rgb(138, 167, 59) 62%, rgb(99, 142, 9) 100%), radial-gradient(center, 50% 110%, ellipse farthest-corner, rgba(255, 255, 255, 0) 99%, rgba(255, 255, 255, 0.2) 99%, rgba(255, 255, 255, 0.2) 100%), radial-gradient(center, ellipse farthest-corner, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.2) 60%, rgba(255, 255, 255, 0.4) 75%, rgba(255, 255, 255, 1) 100%), radial-gradient(center, ellipse farthest-corner, rgba(77, 199, 242, 1) 0%, rgba(14, 107, 154, 1) 100%);
}

.inline-buttons {
  display: flex;
}
.inline-buttons:last-child {
  margin-right: 0;
}

.inline-button {
  flex: 1;
  margin-right: 0.3em;
}

a.inline-button {
  display: block;
}

.button-icon {
  margin-right: 10px;
  opacity: 0.5;
}
