.loader-animation {
  text-align: center;
}

.loader-animation > div {
  background-color: white;
  height: 90px;
  width: 15px;
  display: inline-block;
  margin-left: 1px;
  animation: stretchdelay 1.2s infinite ease-in-out;
}

.loader-animation .rect2 {
  animation-delay: -1.1s;
}

.loader-animation .rect3 {
  animation-delay: -1s;
}

.loader-animation .rect4 {
  animation-delay: -0.9s;
}

.loader-animation .rect5 {
  animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {
  0%, 
  40%, 
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}

@-moz-keyframes stretchdelay {
  0%, 
  40%, 
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}

@-ms-keyframes stretchdelay {
  0%, 
  40%, 
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}

@-o-keyframes stretchdelay {
  0%, 
  40%, 
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}

@keyframes stretchdelay {
  0%, 
  40%, 
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
