table.sortable {

  .ascending, .descending {
    background: $lobbyListRowHoverColor;
    position: relative;
    span {
      width: 20px;
      height: 10px;
      background-image: $componentsSpriteUrl;
      position: absolute;
      background-position: 14px -10px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -15px;
      background-size: 970%;
    }
  }

  .descending {
    span {
      background-position: 14px 11px;
    }
  }
}
