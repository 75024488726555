.status-message {
  margin: 2em 0;
  background-color: rgba(0, 0, 0, .2);
  padding: .7em;
  border-radius: .4em;

  > span > div {
    text-align: center;

    &.title {
      margin: .5834em;
      font-size: 1.2em;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: .1em;
    }

    &.reason {
      margin: .7em;
      line-height: 1.4em;
    }

    &.message {
      margin: .7777em;
      font-size: .9em;
      line-height: 1.4em;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
