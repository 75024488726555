.tournament-position-notice {
  .title {
    padding-bottom: 2% !important; // decrease default to make room
  }
  .winner-icon {
    float: left;
    margin-right: 20px;
  }
}

.tournament-position-notice-content {
  padding-bottom: 20px;
  float: left;
  width: 70%;
  // default ".modal .box .content p" selector is ruining everything
  > p {
    margin-bottom: 5px !important;
  }
  .winnings {
    padding-bottom: 10px;
    > div {
      margin-bottom: 4px !important;
    }
  }
}

.winnings-coupon-list li {
  list-style: disc;
  margin-left: 20px;
}
