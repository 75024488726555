.game-tile {
  width: 100%;
}

.game-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  // stop ability to scroll when content overflows arcade bounds
  overflow: hidden;
  user-select: none;
  display: flex;
  font-size: 1.5em;
}

#arcade .game-tile {
  // transition: transform 0.3s ease, opacity 0.2s ease
  transform: translate(0, -120%);
  transition: transform .3s ease;
  min-width: 0;
  min-height: 0;
  margin: 0 auto;

  @media all and (min-height: 550px) {
    transform: translate(-120%, 0);
  }

  &.current {
    transform: translate(0, 0);

    & ~ .game-tile {
      transform: translate(0, 120%);

      @media all and (min-height: 550px) {
        transform: translate(120%, 0);
      }
    }
  }
}

.game-loading {
  font-size: 2em;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
}

// TODO: Pending delete after mergeing rooms - react Signal has copy of this css
.game-details {
  position: absolute;
  font-size: .8em;
  width: 15%;
  top: 2%;
  left: 1%;
  z-index: 1;
}

.game-details-span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
}

.game-details-span-spacer {
  margin-top: 3%;
}
// END TODO

.game-community {
  position: absolute;
  width: 16em;
  left: 50%;
  top: 12.2em;
  z-index: 20;
  perspective: 350;
  margin-left: -8em;
}


// iPhone < 5:
@media screen and (device-aspect-ratio: 2 / 3) and (-webkit-min-device-pixel-ratio: 2) {
  .game-community {
    width: 30%;
    left: 35%;
  }
}

// iPhone 5:
@media screen and (device-aspect-ratio: 40 / 71) and (-webkit-min-device-pixel-ratio: 2) {
  .game-community {
    width: 29%;
    left: 35.5%;
  }
}

// iPad:
@media screen and (device-aspect-ratio: 3 / 4) and (-webkit-min-device-pixel-ratio: 2) {
  .game-community {
    width: 29%;
    left: 35.5%;
  }
}


.game-pots {
  position: absolute;
  top: 18em;
  z-index: 100;
  width: 100%;
}

.game-pots-inner {
  display: flex;
  justify-content: center;

}

.chip-container {
  margin: 0 .3em;
}

.potTotal, .centerInformation, .handDescription, .rake {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 80%;
  z-index: 15;
}

.potTotal, .centerInformation {
  top: 13em;
}

.handDescription, .rake {
  top: 11.6em;
}

.rake {
  display: none;
}

.dealer {
  position: absolute;
  z-index: 8;
  transition: all 1s ease;
  backface-visibility: hidden;
  perspective: 1000;
  width: 1.6em;
  top: -10%;
}

@media (max-height: 320px) {
  .game-container {
    width: 50em;
    right: 0;
  }
}
