.simple-card {
  height: auto;
  text-align: center;
  background: white;
  border-radius: 5px;
  border: 1px solid #CCC;
  font-weight: bold;
  .rank {
    text-transform: uppercase;
  }
  .suit {
    font-family: Serif;
  }

  &.suit-d, &.suit-h {
    color: rgb(155, 11, 15);
  }
  &.suit-c, &.suit-s {
    color: black;
  }
}

.fourColorDeck .simple-card {
  &.suit-d {
    color: rgb(16, 101, 186);
  }
  &.suit-c {
    color: rgb(13, 139, 10);
  }
}
