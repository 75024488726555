.chat-log {
  grid-area: chat-log;
  flex-grow: 1;
  overflow-y: scroll;
  z-index: 1;
  background-color: #202225;
  font-size: 15px;
  transition: background-color 0.3s ease;
  margin-left: 8px;
  padding: 1em;
  border-radius: .5em .5em 0 0;

  > .chat-log-entry {
    margin: 0;

    &.important {
      color: #b22;
    }

    &.system {
      color: #bbb;
    }

    &.winner {
      color: rgb(253, 150, 34);
    }

    &.notification {
      color: rgb(255, 255, 77);
    }

    &.chat {
      color: #9a9ea7;
    }

    .chat-nickname {
      font-weight: bold;
      padding-right: .3em;

      &::after {
        content: ':';
      }
    }
  }

  &.chat-expanded {
    z-index: 1202;
  }
}

.chat-log + .chat-input {
  > .wrapper {
    border-radius: 0 0 .5em .5em;
    border-top-width: 1px;
  }
  &.maximized-chat.chat-expanded > .wrapper {
    border-radius: .5em
  }
}

.chat-input {
  grid-area: chat;
  z-index: 1203;
  margin: 0 0 8px 8px;
  font-size: 15px;

  &.chat-unavailable > .wrapper {
    > .stickers, > .send {
      opacity: .15;
    }
  }

  &.maximized-chat {
    > .wrapper {
      border: 1px solid #373740;
      padding: 0 .5em;
    }
  }

  > .wrapper {
    border: 0 solid #2d3033;
    border-radius: .5em;
    padding: 0 1em;
    background-color: #202225;
    display: flex;
    height: 100%;
    transition: border-color 0.3s ease;

    > .collapse, > .clear, > .stickers, > .send {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 .2em;
      font-size: 1.5em;
      opacity: 0.7;
    }

    > .collapse {
      margin-right: .4em;
    }

    > .message {
      outline: none;
      border: none;
      flex-grow: 1;
      background-color: transparent;
      font-size: 1em;
      color: #CCC;
      padding: 0;
      font-family: 'Helvetica', sans-serif;
      font-weight: 300;
    }

    > .send{
      font-size: 1.4em;
      padding: 0 .25em;
    }
  }
}

.chat-unavailable-warning {
  grid-area: stats;
  align-self: end;
  background-color: #3c3b42;
  font-size: 15px;
  padding: 1em;
  margin: 0 0 1em 1em;
  border-radius: .5em;
  z-index: 1204;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 20%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: #3c3b42 transparent transparent;
  }
}

.maximized-chat {
  &.chat-log {
    grid-area: stats/stats/actions/actions;
    height: 100%;
    background-color: rgba(0, 0, 0, .75);
    margin: 0;
  }

  &.chat-input.chat-expanded {
    grid-area: chat/chat/actions/actions;
    margin: 0 .5em .5em;
  }
}

.collapse-chat.table-control {
  margin: 1em;
  grid-area: table-controls;
  justify-self: end;
  z-index: 1203;
}


@media screen and (max-height: 70px) {
  .chat-input {
    padding: .3em;
    height: 100%; // the window will be 200px (because of min-height on body) but the keyboard takes up that space so we have to use %
  }
}

@media screen and (max-height: 150px) {
  .collapse-chat.table-control {
    display: none;
  }
}
