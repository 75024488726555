.table-notification-pane {
  position: absolute;
  width: 36%;
  height: 10%;
  top: 33%;
  left: 32%;
  z-index: 122;
}

.table-notification {
  position: absolute;
  width: 100%;
  min-height: 60px;
  background-color: rgb(239, 239, 239);
  background-image: $tableNotificationImage;
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: 5px 5px;
  padding: 2%;
  border: 1px solid rgb(200, 200, 200);
  overflow: scroll;
  color: #444;
  font-size: 70%;
  line-height: 1.4;
  > .fa-times {
    float: right;
    cursor: pointer;
    position: relative;
    bottom: 5px;
  }
  > p {
    margin-top: 0;
    margin-left: 40px;
  }
}

// scale icon depending on resolution
@media all and (max-device-width: 480px) {
  .table-notification {
    background-size: 30px;
    padding-left: 2%;
    background-position: 2px 2px;
    font-size: 90%;
    > .fa-times {
      font-size: 20px;
    }
    > p {
      margin-left: 35px;
    }
  }
}
