// Tables and Filters
html, body {
  height: 100%;
  min-height: 200px;
  overflow: auto;
}

#lobby {
  height: 100%;
  display: grid;
  grid-template-areas: "header header" "sidebar lobby";
  grid-template-columns: 16em auto;
  grid-template-rows: 4em auto;
  background: white url('../common/images/lobby-background.png') 50% 50% repeat;
  max-width: 1280px;
  margin: 0 auto;
  box-shadow: 0 0 10px #000;
  overflow: hidden;
  position: relative; // only here because sidemenu is absolutely positioned on mobile.`

  @media (max-width: 800px) {
    grid-template-areas: "header" "lobby";
    grid-template-columns: auto;
  }

  .gameList {
    height: 100%;
    display: grid;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
  }
}

.format-mobile #lobby {
  grid-template-areas: "header" "lobby";
  grid-template-columns: auto;

  > span, > div {
    min-height: 0;
    overflow: auto;
  }
}

.lobbyTable {
  color: #2f3e46;
  width: 100%;
  border-collapse: collapse;
  font-size: 90%;
  text-align: left;

  tr {
    position: relative;
  }

  td, th {
    cursor: pointer;
    background: $lobbyListRowColor;
    border-top: 1px solid;
    border-color: #e8e8e8;
    color: $lobbyListFontColor;
    padding: 6px 2px;
  }

  .mtt td {
    padding: 10px 2px; // to make more even with SNG / MiniSeats
  }

  tr:nth-child(even) td {
    background: $lobbyListRowAltColor;
    color: $lobbyListFontAltColor;
  }

  tr.selected td, tr:hover td {
    background: $lobbyListRowHoverColor;
  }

  tr.playing td, tr.registered td {
    background: $lobbyListRowPlayingColor;
  }

  td:last-child {
    position: relative;
  }

  td.filterMessage, td.errorMessage {
    text-align: center;
    background: $lobbyListRowColor !important;
    padding: 60px 0;
  }

  th.blank {
    width: 20px; // hack to stop pin column collapsing
  }

  .loading td {
    padding: 0;
  }

  .sng .no-wrap {
    text-align: center;
  }

  .no-wrap {
    white-space: nowrap;
    padding-right: 8px;
  }
}

th.miniSeats {
  width: 60px;
  span {
    display: none; // temp hack for sorter thing
  }
}


.loading-overlay-desktop {
  position: fixed;
  padding-top: 15%;
  text-align: center;
  font-style: italic;
  font-size: 150%;
  width: 100%;
  height: 1000px;
  z-index: 1;
  max-width: 1030px;
  background: rgba(0, 0, 0, 0.7);
  color: #EEE;
  margin-top: -30px; // to cover headings too
  &:hover {
    // cancel hover style. can't use gradient because
    // then the transition goes via transparent which
    // shows the list underneath
    background: rgba(0, 0, 0, 0.7);
  }
}

.format-tablet .loading-overlay-desktop {
  margin-top: -32px; // to cover headings too
}
