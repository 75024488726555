.progress-bar {
  position: relative;
  .progress-bar-meter {
    position: absolute;
    top: 0;
    height: 100%;
    background: white;
    opacity: 0.1;
  }
  .progress-bar-label {
    position: absolute;
    top: 0;
    left: 6px;
    height: 100%;
    padding-top: 4px; // vertical alignment hack for active bonuses screen.
  }
}

td.progress-bar {
  padding: 0;
  border: 1px solid #666;
  height: 43px;
}

.progress-bar-column {
  min-width: 200px;
}
