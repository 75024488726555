.table-controls {
  grid-area: table-controls;
  justify-self: end;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  align-self: start;
  max-width: 14em;
  flex-wrap: wrap;
}

.table-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 6em;
  height: 6em;
  background: #272727;
  box-shadow: 0 1px 2px rgba(0,0,0,.3), 0 1px 3px 1px rgba(0,0,0,.2);
  border-radius: 10em;
  margin: 1em 1em 0 0;
  color: #999;
  font-size: 1em;

  &.disabled {
    opacity: .5;
  }

  span {
    font-size: 2.4em;

    &.pop-in {
      transform: rotate(180deg);
      padding: .15em 0 0 .15em;
    }

    &.pop-out {
      padding: .1em 0 0 .1em;
    }
  }
}
