.plainTable {
  border-collapse: collapse;
  width: 100%;
  th {
    background: #F5F5F5;
    border: 1px solid #ddd;
    font-weight: normal;
    font-size: 90%;
    cursor: pointer;
    &:hover {
      background: #ddd;
    }
  }
  td {
    background: white;
    cursor: pointer;
  }

  tr:hover {
    td {
      background: #F0F0F0;
    }
  }

  tr.active {
    td {
      background: rgb(51, 153, 255);
    }
  }
}
