.format-message {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  margin: 1em;
  text-align: center;
}

.settings.fancyList {
  display: flex;
  flex-direction: column;
  align-content: center;

  > label, .label {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    margin-left: 1em;
    margin-right: 1em;
    display: flex;
    height: 1.5em;

    &.round {
      margin-bottom: 1em;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    &.heading {
      margin-top: 1em;
    }

    &.autoRebuyAmount {
      height: auto;
    }

    > .button {
      bottom: 0;
    }

    > .grow {
      flex: 1;
      order: 1;
    }

    > .number-input {
      bottom: 0;
      order: 1;
    }

    > .circleLink {
      bottom: 0;
      order: 1;
    }

    > .right {
      text-align: right;
    }

    > * {
      align-self: center;
      order: 2;
    }

    &.column {
      flex-direction: column;
      > * {
        align-self: unset;
      }
    }
  }
}
