.pane__toggleAll {
  position: absolute;
  bottom: 10px;
  right: 5px;
  width: 25%;
}

.selectorfilter {
  display: none;
  background: linear-gradient(180deg, #333 0%, #222 100%);
  flex-shrink: 0;
  height: 200px;
}
.selectorfilter-list {
  float: left;
  width: 25%;
  height: 200px;
}
.selectorfilter-list-item {
  padding: 10px;
  background: $quickFilterButtonBackground;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 9px;
  cursor: pointer;
  &:hover {
    background: darken($quickFilterButtonBackground, 5);
  }
  &.active {
    background: $quickFilterButtonActiveBackground;
  }
}
