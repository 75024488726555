.timebank {
  position: absolute;
  background-size: 100%;
  padding-bottom: 6.5%;
  // bottom: 15%;
  bottom: 3.5em;
  width: 9%;
  right: 1%;
  height: 0;
  z-index: 1;
  cursor: pointer;
}

.timebank-sprite {
  position: absolute;
  width: 100%;
  padding-top: 73%;
}

.timebank-sprite-on {
  display: block;
}

.timebank-label {
  position: absolute;
  bottom: 10%;
  text-align: center;
  width: 100%;
  font-size: 70%;
  z-index: 111;
  display: block;
}

.usingTimebank:empty {
  display: none;
}

.usingTimebank {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 14% 15% 13% 9%;
  backdrop-filter: blur(6px);
}

.timebank-pie {
  width: 50%;
  height: 100%;
  position: relative;
  float: left;
  background: rgba(255, 0, 0, 0.5);
  padding: 0;
  margin: 0;
}

.usingTimebank .face {
  stroke: #A00;
}

.timebank-timerRotate {
  border-radius: 100% 0 0 100% / 50% 0 0 50%;
  z-index: 100;
  transform-origin: 100% 50%;
  animation: timerRotate linear 1;
}

.timebank-timerRotate:after {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 0px 5px 1px;
  border-color: transparent transparent #000000 transparent;
  content: "";
  display: block;
  z-index: 1000;
}

.timebank-timerFill {
  border-radius: 0 100% 100% 0 / 0 50% 50% 0;
  opacity: 1;
  z-index: 300;
  animation: timerFill steps(1, end) 1;
}

.timebank-mask {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  background-image: $timebankSpriteUrl;
  background-size: 200% 100%;
  z-index: 300;
}

.timebank-leftMask {
  left: 0;
  background-position: initial;
  animation: leftMask steps(1, end) 1;
  animation-fill-mode: forwards;
}

.timebank-rightMask {
  right: 0;
  width: 52%;
  background-position: 100%;
  animation: rightMask steps(1, end) 1;
  animation-fill-mode: forwards;
}

@-webkit-keyframes timerRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes timerRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-ms-keyframes timerRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes timerRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes timerRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes leftMask {
  0% {
    opacity: 1;
    z-index: 200;
  }
  49%, 100% {
    z-index: 50;
  }
}

@-moz-keyframes leftMask {
  0% {
    opacity: 1;
    z-index: 200;
  }
  49%, 100% {
    z-index: 50;
  }
}

@-ms-keyframes leftMask {
  0% {
    opacity: 1;
    z-index: 200;
  }
  49%, 100% {
    z-index: 50;
  }
}

@-o-keyframes leftMask {
  0% {
    opacity: 1;
    z-index: 200;
  }
  49%, 100% {
    z-index: 50;
  }
}

@keyframes leftMask {
  0% {
    opacity: 1;
    z-index: 200;
  }
  49%, 100% {
    z-index: 50;
  }
}

@-webkit-keyframes rightMask {
  0% {
    z-index: 50;
  }
  49%, 100% {
    z-index: 200;
  }
}

@-moz-keyframes rightMask {
  0% {
    z-index: 50;
  }
  49%, 100% {
    z-index: 200;
  }
}

@-ms-keyframes rightMask {
  0% {
    z-index: 50;
  }
  49%, 100% {
    z-index: 200;
  }
}

@-o-keyframes rightMask {
  0% {
    z-index: 50;
  }
  49%, 100% {
    z-index: 200;
  }
}

@keyframes rightMask {
  0% {
    z-index: 50;
  }
  49%, 100% {
    z-index: 200;
  }
}

@-webkit-keyframes timerFill {
  0% {
    opacity: 0;
  }
  49%, 100% {
    opacity: 1;
  }
}

@-moz-keyframes timerFill {
  0% {
    opacity: 0;
  }
  49%, 100% {
    opacity: 1;
  }
}

@-ms-keyframes timerFill {
  0% {
    opacity: 0;
  }
  49%, 100% {
    opacity: 1;
  }
}

@-o-keyframes timerFill {
  0% {
    opacity: 0;
  }
  49%, 100% {
    opacity: 1;
  }
}

@keyframes timerFill {
  0% {
    opacity: 0;
  }
  49%, 100% {
    opacity: 1;
  }
}
