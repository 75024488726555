:root {
  --headerTabHoverBackground: linear-gradient(180deg, #333333 0%, #191919 100%);
  --lobbyHeaderBackground: linear-gradient(180deg, rgba(60, 60, 60, 1) 0%, rgba(35, 35, 35, 1) 100%);
  --lobbyTrimColor: #000;
  --lobbyTrimColorDarkened: #000;
  --lobbyToolbarTabBackground: #222;
  --lobbyToolbarTabSelectedBackground: linear-gradient(180deg, var(--lobbyTrimColor) 0px, var(--lobbyTrimColorDarkened) 100%);

  /* game list selection */
  --gameListSelectionButtonSelectedColor: #000;
  --gameListSelectionButtonHue: 0;
  --gameListSelectionButtonSaturation: 0%;
  --gameListSelectionButtonLightness: 13%;
  --gameListSelectionButtonColor: hsl(
    var(--gameListSelectionButtonHue),
    var(--gameListSelectionButtonSaturation),
    var(--gameListSelectionButtonLightness)
  );
  --gameListSelectionButtonHoverColor: hsl(
    var(--gameListSelectionButtonHue),
    var(--gameListSelectionButtonSaturation),
    calc(var(--gameListSelectionButtonLightness) - 5%)
  );

  --quickSeatTabSelectedTrim: rgb(38, 115, 175);
  --quickSeatHeadingBackground:  linear-gradient(180deg, #3C3C3C 0%, #232323 100%);
  --quickSeatRowBackground: linear-gradient(to bottom, #F6F6F6, #ffffff);
  --quickSeatRowHoverBackground: linear-gradient(to bottom, #EDEDED, #DADADA);
  --quickSeatRowSeparator: #E8E8E8;
  --quickSeatRowText: #2F3E46;

  --modalBackground: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.8) 100%);
  --modalBorderColor: #bebebe;
  --modalOverlayColor: rgba(0, 0, 0, 0.5);
  --modalShadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.5);
  --modalTextColor: #ffffff;

  --defaultAdBarBackground: #f0f0f0 url('../common/images/sidepanel.jpg') center/100%;
}
