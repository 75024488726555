.activeBonuses .generic-modal-table {
  border-collapse: separate;
  border-spacing: 0px 8px;
  tr {
    &:first-child button.move-up {
      visibility: hidden;
    }
    &:last-child button.move-down {
      visibility: hidden;
    }
    .subtext {
      font-size: 80%;
      opacity: 0.8;
    }
  }
  tbody.complete {
    .move-down, .move-up {
      display: none;
    }
  }
  .button, button {
    &:hover {
      opacity: 0.9;
    }
    &:active {
      opacity: 0.7;
    }
  }
}

@media all and (max-width: 768px) and (orientation: portrait) {
  .move-down, .move-up {
    padding: 0.2em 0.8em;
  }
}
