.tooltip {
  position: relative;
}

.tooltip:before {
  display: none;
  position: absolute;
  right: 0;
  width: 100px;
  content: attr(title);
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  // background-color: #222
  // border: 1px solid #111
  font-family: arial;
  font-size: 13px;
  border-radius: 2px;
  color: #eee;
  z-index: 200;
  top: 100%;
  opacity: 0.8;
}

.tooltip:hover:before, .tooltip.show:before {
  display: block;
}

.button.tooltip {
  overflow: visible;
}
