:root {
  --headerHomeLogo: url('images/logoColor.svg') -2.9em center no-repeat;
  --headerHomeLogoSize: auto 350%;
  --lobbyTrimColor: #800;
  --lobbyTrimColorDarkened: #570000;
  --tableFeltColor: #444b53;
  --tableLogo: url('./images/logoColor.svg');
  --tableLogoSize: 50%;
  --tableRailColor: hsl(204deg 9% 21%);
  --tableRailColorHighlight: hsl(215deg, 13%, 47%);
  --tableRailColorShadow: hsl(215deg, 13%, 13%);
  --tableRailInnerColor: transparent;
}
