body {
  font-family: 'Helvetica', sans-serif;
  font-weight: 300;
  margin: 0px;
  padding: 0px;
  font-size: 12pt;
  color: $textColor;
  background: $bodyBackground;
  background-attachment: fixed;
  background-size: cover;
  -webkit-text-size-adjust: none;

  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}

@media screen and (max-height: 70px) {
  html, body {
    min-height: 0;
    overflow: hidden;
  }
}

a {
  color: #FFFFFF;
  text-decoration: none;
}

.link {
  cursor: pointer;
  color: $linkTextColor;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

textarea {
  resize: none;
}

input, textarea {
  width: 100%;
  font-size: 100%;
  box-sizing: border-box;
  padding: 3px;
  border: none;
  vertical-align: middle;
}

input, a, textarea {
  border-radius: 0.2em;
}

input[type="radio"],
input[type="checkbox"] {
  border-radius: 0px;
  width: auto !important;
}

input.button.disabled, a.button.disabled, button.button.disabled, div.button.disabled {
  color: #CCC;
  font-weight: bold;
  cursor: pointer;
}

// disable the border glow when item is clicked
div, span, label, input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.loggingOut {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $loginLogo;
}

.clear {
  clear: both;
}

@mixin selfclear() {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

.selfclear {
  @include selfclear();
}

.loading-text-center {
  text-align: center;
  font-size: 16px;
  font-style: normal;
}

input[type=checkbox].css-checkbox {
  display: none; // trying this instead of pos:abs (see above). appears to be working for now
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

// unused way to try add a :focus style for tabbing through checkboxes
// input[type=checkbox].css-checkbox:focus + label.css-checkbox-label
//   background: #007eff

input[type=checkbox].css-checkbox + label.css-checkbox-label {
  padding-left: 20px;
  height: 17px;
  display: block;
  line-height: 17px;
  background-repeat: no-repeat;
  background-position: 0 0;
  // font-size:15px
  vertical-align: middle;
  cursor: pointer;
}

input[type=checkbox].css-checkbox:checked + label.css-checkbox-label {
  background-position: 0 -17px;
}

input[type=checkbox].css-checkbox:disabled + label.css-checkbox-label {
  opacity: 0.5;
}

input[type=checkbox].css-checkbox.hidden + label.css-checkbox-label {
  display: none;
}

.css-checkbox-float-right {
  float: right;
}

.css-checkbox-label {
  background-image: $checkboxSprite;
}

.classic-list {
  padding-left: 20px;
  li {
    list-style-type: disc;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .css-checkbox-label {
    background-image: $checkboxSprite2x;
    background-size: 17px;
  }
}
