// base classes for all modalViews
// to modify cascade the rules in their relevant component styl

.modal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;

  .box {
    max-width: 1140px;
    -ms-flex-preferred-size: 1140px;
    border-radius: 0.2em;
    width: auto;
    padding: 2%;
    margin: 2%;
    background: rgba(0, 0, 0, 0.8);; // so unsupported notice looks ok for non webkit browsers
    background: $modalBackground;
    border: 0.3em solid grey;
    border-color: $modalBorderColor;
    box-shadow: $modalShadow;
    color: $modalTextColor;
    z-index: 10002;
    width: 91%;
    // flex: 1
    max-height: 90%;
    overflow-y: scroll;

    button, .button {
      font-size: 110%;
    }

    .title {
      font-size: 180%;
      font-weight: bold;
      padding-bottom: 5%;
      margin: 0 auto;
      text-align: center;
    }

    .content {
      font-size: 100%;
      padding-bottom: 5%;
      .option {
        padding-top: 5%;
      }
      p {
        margin-bottom: 1.5em;
      }
      .warningText {
        color: red;
      }
    }

    .choices {
      width: 100%;
    }

    input[name=username] {
      width: 30%;
      margin-left: 2%;
    }
  }

  .column {
    width: 29%;
    padding: 0 2% 2%;
    float: left;
  }
}

// Overlay is clickable the transparent box behind the modal - click to dismiss modal
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10001;
  background: $modalOverlayColor;
  display: flex;
  align-items: center;
  justify-content: space-around;
  &.opaque {
    background: #222;
  }
  &.transparent {
    // background: rgba(255, 0, 0, 0.1)  // good for debugging overlay issues
    background: transparent;
  }
}

.modal.minimal .box {
  padding: 0;
}

$bmargintb: 9%;
$bmarginlr: 12%;
$bpadding: 5%;

.modal-loading {
  background: rgba(0, 0, 0, 0.5);
  padding: 40px;
}

.box.bountyPrize .content > div {
  margin-top: 2%;
}

.generic-modal-table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  td, th {
    background: rgba(0, 0, 0, 0.3);
    padding: 2px 0 2px 10px;
    text-align: left;
  }
  tr:nth-child(even) td {
    background: rgba(0, 0, 0, 0.2);
  }

  .maxBet {
    display: none;
  }
  &.fixedLimit .maxBet {
    display: table-cell;
  }
}

.radio-wrapper {
  padding: 5px 0;
}

.title-icon {
  margin-right: 20px;
  opacity: 0.8;
}

.large-coupon-input {
  display: block;
  max-width: 600px;
  margin: auto;
  font-size: 200%;
  text-align: center;
}

.report-a-problem-modal {
  input, textarea {
    margin-bottom: 4px;
    padding: 4px;
  }
}

.reactModal {
  position: absolute;
  padding: 2%;
  width: 90%;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: 0.3em solid #bebebe;
  border-radius: 0.2em;
  background: rgba(0, 0, 0, 0.8);

  .button {
    font-size: 110%;
  }

  .title {
    font-size: 180%;
    font-weight: bold;
    padding-bottom: 5%;
    margin: 0 auto;
    text-align: center;
  }

  .content {
    font-size: 100%;
    padding-bottom: 5%;
    .option {
      padding-top: 5%;
    }
    p {
      margin-bottom: 1.5em;
    }
    .warningText {
      color: red;
    }
  }

  &:focus {
    outline:0;
  }
}

.reactModalOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: $modalOverlayColor;
    z-index: 20000;
}

.centerText {
  text-align: center;
}
