.modal .box.switch-format-modal .content {
  padding-bottom: 3%;
}

@media all and (orientation: portrait) {
  .switch-format-modal {
    .choices {
      display: block; // clear flex
      .button {
        padding-left: 0.5em;
        padding-right: 0.5em;
        margin-bottom: 0.5em;
      }
    }
  }
}
