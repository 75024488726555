.fancyList {
  margin: 0;
  background: $settingsBackground;
  overflow: auto;

  // .label class is for divs styled as labels (containing checkboxes, etc)
  label:not(.css-checkbox-label), .label {
    position: relative;
    background: #f6f6f6;
    background: $settingsRowBackground;
    border-top: 1px solid;
    border-color: $settingsRowSeparator;
    color: $settingsRowText;
    padding: 10px;
    cursor: pointer;
    display: block;

    .number-input {
      width: 60px;
      text-align: center;
      border: 1px solid #ccc;
      position: relative;
      bottom: 6px;
      margin-right: 6px;
    }

    input, select, .button, .right {
      float: right;
      &.noFloat {
        float: none;
      }
    }

    .button {
      position: relative;
      bottom: 5px;
    }

    &:hover,
    &.selected {
      background: $settingsRowHoverBackground;
    }

    &.heading {
      background: $settingsHeadingBackground;
      color: $settingsHeadingText;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
    }

    &.hidden {
      display: none !important;
    }

    &.round {
      margin-bottom: 2%;
    }
  }

  .label {
    select {
      clear: both;
    }
  }
}

.circleLink {
  width: 20px;
  display: block;
  float: right;
  text-align: center;
  background: $tableFeltColor;
  color: #fff;
  font-style: normal;
  border-radius: 15px;
  margin-right: 10px;
  box-shadow: 1px 1px 1px #888;
  padding: 5px; // made very large and shifted up slightly to provide larger "clickable" area
  opacity: 0.2;
  position: relative;
  bottom: 5px;
  transition: 0.2s ease;
  &:hover {
    opacity: 1;
  }
}

.selected .circleLink {
  opacity: 1;
}

@media all and (max-width: 360px) {
  .autoRebuy {
    .help-icon {
      margin-bottom: 10%;
    }
    // Commented out by Jake. Couldn't figure out why they were here
    select {
      max-width: 50%;
      // position: absolute
      // right: 10px
      // top: 4em
    }
  }
}
