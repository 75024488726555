.icon {
  display: inline-block;
  height: $iconSprite_128_scale;
  width: $iconSprite_128_scale;
  background-image: $iconSprite_128_url;
  background-size: $iconSprite_128_size;
}

.icon-label {
  font-size: 60%;
}
.icon-home {
  background-position: $iconSprite_128_scale * 0 0px;
}
.icon-lobby {
  background-position: -($iconSprite_128_scale * 1) 0px;
}
.icon-tables {
  background-position: -($iconSprite_128_scale * 2) 0px;
}
.icon-search {
  background-position: -($iconSprite_128_scale * 3) 0px;
}
.icon-settings {
  background-position: -($iconSprite_128_scale * 4) 0px;
}
.icon-friend {
  background-position: -($iconSprite_128_scale * 5) 0px;
}
.icon-help {
  background-position: -($iconSprite_128_scale * 6) 0px;
}
.icon-megaphone {
  background-position: -($iconSprite_128_scale * 7) 0px;
}
.icon-sort {
  background-position: -($iconSprite_128_scale * 8) 0px;
}
.icon-filter {
  background-position: -($iconSprite_128_scale * 9) 0px;
}
.icon-pin {
  background-position: -($iconSprite_128_scale * 10) 0px;
}
.icon-smiley {
  background-position: -($iconSprite_128_scale * 11) 0px;
}
.icon-addchips {
  background-position: -($iconSprite_128_scale * 12) 0px;
}
.icon-history {
  background-position: -($iconSprite_128_scale * 13) 0px;
}
.icon-cashier {
  background-position: -($iconSprite_128_scale * 15) 0px;
}
.icon-seat {
  background-position: -($iconSprite_128_scale * 16) 0px;
}
.icon-folder {
  background-position: -($iconSprite_128_scale * 17) 0px;
}
.icon-playMoney {
  background-position: -($iconSprite_128_scale * 18) 0px;
}
.icon-menu {
  background-position: -($iconSprite_128_scale * 19) 0px;
}
.icon-logout {
  background-position: -($iconSprite_128_scale * 20) 0px;
}
.icon-casino {
  background-position: -($iconSprite_128_scale * 21) 0px;
}
.icon-football {
  background-position: -($iconSprite_128_scale * 22) 0px;
}

.icon-coupon {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.icon-coupon {
  background-image: url('../common/images/icon-coupon.png');
}

.icon-satellite {
  float: left;
  display: inline-block;
  width: 26px;
  height: 23px;
  background-image: url('../common/images/satellites.png');
}

// 298 x 455 aspect ratio = ~0.65
.winner-icon {
  width: 100px;
  height: 150px;
  background-size: cover;
}
.winner-icon-gold {
  background-image: url('../common/images/goldCup.png');
}
.winner-icon-silver {
  background-image: url('../common/images/silverCup.png');
}
.winner-icon-bronze {
  background-image: url('../common/images/bronzeCup.png');
}
.winner-icon-flag {
  background-image: url('../common/images/checkeredFlag.png');
}
