// Game view, buttons on the bottom right hand includes, timebank, options and lobby

.clientActions {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  font-size: 1.5em;
}

.client-sprite {
  display: inline-block;
}

#mobileChatButtons {
  position: absolute;
  bottom: 15%;
  margin-top: -10%;
  left: 8px;
  padding: 1% 0;
  width: 8em;
  z-index: 11; // tableLogo is 10
}

.mobile-chat-sprite {
  width: 40%;
  padding-top: 26%;
  float: left;

  &.disabled {
    opacity: 0.2;
    cursor: default;
  }
}

.sprite-iconChat {
  margin-right: 10%;

  &.disabled {
    opacity: 0.2;
  }
}

.leaveGameBtn,
.gameOptionsBtn {
  position: absolute;
  z-index: 11; // tableLogo is 10
  right: 9%;
  top: 3%;

  .button-icon {
    margin-right: 0;
  }
}

#arcade {

  .leaveGameBtn,
  .gameOptionsBtn {
    display: none !important;
  }
}

.pop-in-button,
.pop-out-button {
  position: absolute;
  z-index: 11;
  right: 2%;
  top: 3%;
  opacity: 0.8;

  .fa {
    transform: rotate(45deg);
  }

  &.small {
    font-size: 50%;
  }

  &:hover {
    opacity: 1;
  }
}

.format-mobile {

  .leaveGameBtn,
  .gameOptionsBtn {
    top: 6%;
  }
}

.breakNotice {
  position: absolute;
  top: 17em;
  left: 50%;
  z-index: 111;

  p {
    float: left;
    font-size: 70%;
    margin: 20% -50%;
    text-align: center;
    color: rgb(192, 192, 192);
  }

  p::first-line {
    color: $tableHighlightTextColor;
  }

  .breakResume {
    font-size: 170%;
  }

  .endTime {
    font-weight: bold;
  }
}

.format-mobile .breakNotice p {
  margin: 40% -50%;
}

.waitlist {
  position: absolute;
  bottom: 28%;
  right: 1%;
  width: 12%;
  height: 5%;
  text-align: right;
  cursor: pointer;
  z-index: 11;
  font-size: 80%;
}



@media (max-height: 320px) {
  .breakNotice {
    left: auto;
    right: 13em;
  }

  .table-notification-pane {
    left: auto;
    right: 13em;
  }

  .centerInformation {
    right: 3em;
    width: 58em;
  }

  #seats {
    position: absolute;
    right: 0;
  }
}
