.modal .rematch-modal {
  .title {
    padding-bottom: 3%;
  }
  .content {
    padding-bottom: 3%;
  }
}

.rematch-content {
  text-align: center;
  select {
    font-size: 150%;
  }
}

.countdown-timer {
  text-align: center;
  font-size: 200%;
  margin-top: 4%;
}

.rematch-content .avatar {
  width: 100px;
  height: 100px;
}

.pane__vs {
  display: flex;
  width: 60%;
  margin: 0 auto;
}
.rematch-vs-pane {
  flex: 1;
  align-self: center;
  .avatar, .alias {
    display: block;
    text-align: center;
    margin: auto;
    float: none;
  }
}

@media screen and (max-width: 640px) {
  .rematch-content .avatar {
    width: 80px;
    height: 80px;
  }
}
