
@supports (display: grid) and (display: flex) {
  .fullscreen-container.browser-not-supported-css {
    display: none;
  }
}

.fullscreen-container.browser-not-supported {
  z-index: 1;

  &.dismissed {
    display: none;
  }
}
