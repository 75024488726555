td.preview {
  background: -webkit-linear-gradient(top, #ededed 0%, #dadada 100%) !important;
  padding: 0;
  cursor: default;
}

.tournamentPreview {
  h3, h4 {
    margin: 5px 10px 10px; // ugh
  }
  h4 {
    height: 20px;
  }
  .startsIn {
    float: right;
    background: white;
    border-radius: 15px;
    padding: 1%;
    text-align: center;
    font-size: 90%;
    margin: 0 10px;
  }
  .pane__right {
    float: right;
    padding: 20px;
    width: 20%;
    border-left: 1px solid #DDD;
    background: white;
    select {
      width: 100%;
    }
  }
  .pane__dates {
    margin-right: 24%;
    > div {
      width: 24%;
      display: inline-block;
      vertical-align: top;
      border: 1px solid #EEE;
      font-size: 90%;
      background: rgba(255, 255, 255, 0.3);
      text-align: center;
      label {
        display: block;
        padding: 5px;
        background: rgba(255, 255, 255, 0.4);
      }
      span {
        display: block; // NO
        height: 10px;
        padding: 5px;
      }
    }
  }
}
