.fullscreen-container {
  background: $bodyBackground;
  display: flex;
  overflow: scroll;
  width: 100%;
  height: 100%;
  position: absolute;

  > .scroll-container {
    margin: auto;
    width: 30em;
    max-width: 100%;
    padding: 2em;

    // HACK: IE11 doesn't respect margin auto on flex children
    // https://stackoverflow.com/questions/37534254/flex-auto-margin-not-working-in-ie10-11
    align-self: center;
  }
}
