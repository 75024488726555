#arcade {
  background: $carpetColor radial-gradient(ellipse at center, lighten($carpetColor, 20%) 0%, $carpetColor 80%);
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: "preview-bar game-tile";
  grid-template-columns: 6em auto;
  grid-template-rows: 100%;
  overflow: hidden;

  @media all and (min-height: 550px) {
    // font-size: .75em;

    grid-template-areas: "preview-bar" "game-tile";
    grid-template-columns: 100%;
    grid-template-rows: 4em auto;
  }

  .preview-bar {
    grid-area: preview-bar;
  }

  .game-tile {
    grid-area: game-tile;
  }
}

#arcade.popout {
  grid-template-columns: 100%;
  grid-template-areas: "game-tile";
  grid-template-rows: 100%;
}
