@mixin dealer-position ($index, $dealer-top, $dealer-left) {
  .dealer#{$index} {
    top: $dealer-top;
    left: $dealer-left;
  }
}

@mixin player-position ($index, $player-top, $player-left, $chip-top, $chip-left) {
  .player#{$index} {
    top: $player-top;
    left: $player-left;

    .chip-container {
      position: absolute;
      top: $chip-top;
      left: $chip-left;
    }
  }
}

@mixin seat-left ($index) {
  @include dealer-position($index, 9.6em, 22%);
  @include player-position($index, 58%, 11%, 25%, 105%);
}

@mixin seat-left-top ($index) {
  @include dealer-position($index, 7.3em, 22%);
  @include player-position($index, 46%, 11%, 50%, 105%);
}

@mixin seat-left-bottom ($index) {
  @include dealer-position($index, 61%, 22%);
  @include player-position($index, 73.5%, 11%, 0%, 105%);
}

@mixin seat-right ($index) {
  @include dealer-position($index, 48%, 75.5%);
  @include player-position($index, 58%, 89%, 25%, -73%);
}

@mixin seat-right-top ($index) {
  @include dealer-position($index, 27%, 75%);
  @include player-position($index, 46%, 88.5%, 50%, -73%);
}

@mixin seat-right-bottom ($index) {
  @include dealer-position($index, 59%, 75%);
  @include player-position($index, 73.5%, 88.5%, 0%, -73%);
}

@mixin seat-top-left ($index) {
  @include dealer-position($index, 17%, 25%);
  @include player-position($index, 22%, 35%, 120%, 30%);
}

@mixin seat-top-middle ($index) {
  @include dealer-position($index, 17%, 41.8%);
  @include player-position($index, 22%, 50%, 115%, 35%);
}

@mixin seat-top-right ($index) {
  @include dealer-position($index, 17%, 72%);
  @include player-position($index, 22%, 65%, 120%, 30%);
}

@mixin seat-bottom-middle ($index) {
  @include dealer-position($index, 64.5%, 40%);
  @include player-position($index, 100%, 50%, -135%, 30%);
}

@mixin seat-bottom-left ($index) {
  @include dealer-position($index, 64.5%, 25%);
  @include player-position($index, 100%, 35%, -135%, 30%);
}

@mixin seat-bottom-right ($index) {
  @include dealer-position($index, 64.5%, 72%);
  @include player-position($index, 100%, 65%, -135%, 30%);
}

@mixin seat-bottom-far-right ($index) {
  @include dealer-position($index, 64.5%, 60%);
  @include player-position($index, 100%, 72%, -135%, 10%);
}

.seats2 {
  @include seat-left(0);
  @include seat-right(1);
}

.seats3 {
  @include seat-top-left(0);
  @include seat-top-right(1);
  @include seat-bottom-middle(2);
}

.seats4 {
  @include seat-top-left(0);
  @include seat-top-right(1);
  @include seat-bottom-right(2);
  @include seat-bottom-left(3);
}

.seats5 {
  @include seat-left(0);
  @include seat-top-left(1);
  @include seat-top-right(2);
  @include seat-right(3);
  @include seat-bottom-middle(4);
}

.seats6 {
  @include seat-left(0);
  @include seat-top-left(1);
  @include seat-top-right(2);
  @include seat-right(3);
  @include seat-bottom-right(4);
  @include seat-bottom-left(5);
}

.seats7, .seats8 {
  @include seat-left-top(0);
  @include seat-top-left(1);
  @include seat-top-right(2);
  @include seat-right-top(3);
  @include seat-right-bottom(4);
  @include seat-bottom-right(5);
  @include seat-bottom-left(6);
  @include seat-left-bottom(7);
}

.seats9 {
  @include seat-left-top(0);
  @include seat-top-left(1);
  @include seat-top-right(2);
  @include seat-right-top(3);
  @include seat-right-bottom(4);
  @include seat-bottom-far-right(5);
  @include seat-bottom-middle(6);
  // @include seat-bottom-left(7) // Custom css (see below)
  @include seat-left-bottom(8);


  .dealer7 {
    top: 64.5%;
    left: 25%;
  }

  .player7 {
    left: 28%;
    top: 100%;

    .chip-container {
      position: absolute;
      top: -135%;
      left: 50%;
    }
  }
}

.seats10 {
  @include seat-left-top(0);
  // @include seat-top-left(1) // Custom css (see below)
  @include seat-top-middle(2);
  // @include seat-top-right(3) // Custom css (see below)
  @include seat-right-top(4);
  @include seat-right-bottom(5);
  @include seat-bottom-far-right(6);
  @include seat-bottom-middle(7);
  // @include seat-bottom-left(8) // Custom css (see below)
  @include seat-left-bottom(9);

  .dealer1 {
    top: 17%;
    left: 20%;
  }

  .player1 {
    left: 28%;
    top: 22%;

    .chip-container {
      position: absolute;
      top: 115%;
      left: 35%;
    }
  }

  .dealer3 {
    top: 17%;
    left: 63.7%;
  }

  .player3 {
    left: 72%;
    top: 22%;

    .chip-container {
      position: absolute;
      top: 115%;
      left: 35%;
    }
  }

  .dealer8 {
    top: 58.5%;
    left: 35%;
  }

  .player8 {
    left: 28%;
    top: 100%;

    .chip-container {
      position: absolute;
      top: -135%;
      left: 35%;
    }
  }
}
