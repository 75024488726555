.box {
  &.tableOptions,
  &.leaveGame,
  &.sittingOut,
  &.bettingControlsModal,
  &.fastTableNotice {
    width: 38em;
    max-width: 90%;
  }
}
