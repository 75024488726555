/*
 *  Variables used in web client.
 *
 *  Rules listed here can be casino customisable, use casinos/casinoname.scss to overide these global default values
 *
 *  All global variables have a $ prefix, local variables don't
 *
 *  TODO:
 *  - deconstruct gradients/background properties into individual colour declarations
 *
 */

$textColor: #ffffff;
$altTextColor: #222;
$altTextColorLight: #444;
$linkTextColor: #3E97F6;

// Buttons
$buttonTextColor: #ffffff;
$buttonColor: #5D5C57;
$buttonHoverColor: #7a7a7a;
$buttonBorderRadius: 0.2em;
$buttonBackground: linear-gradient(180deg, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.55) 1px, rgba(0, 0, 0, 0) 100%);

$buttonCtaBackground: #888;

// Tab buttons
$tabTextColor: #ffffff;
$tabBackground: none;
$tabHoverBackground: linear-gradient(180deg, #333333 0%, #191919 100%);
$tabSelectedBackground: radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 99%, rgba(0, 0, 0, 0) 100%);

// Login Screen
$loginLogoSize: contain;

// Lobby
$lobbyHomeLogoSize: contain;
$lobbyHomeLogoPosition: 5px left;
$lobbyTrimColor: #000000;
$lobbyTabSelectedTrim: $lobbyTrimColor;
$lobbyHeaderTextColor: #ffffff;
$lobbyHeaderBackground: linear-gradient(180deg, rgba(60, 60, 60, 1) 0%, rgba(35, 35, 35, 1) 100%);
$lobbySearchBackground: #dddddd;
$lobbySearchTextColor: #404040;
$lobbyListFontColor: #2F3E46;
$lobbyListRowColor: linear-gradient(to bottom, #F6F6F6, #ffffff);
$lobbyListFontAltColor: $lobbyListFontColor;
$lobbyListRowAltColor: $lobbyListRowColor;
$lobbyListRowHoverColor: linear-gradient(to bottom, #EDEDED, #DADADA);
$lobbyListRowPlayingColor: #fff9d5;
$miniTableBackground: radial-gradient(center, ellipse farthest-corner, rgba(0, 87, 0, 1) 4%, rgba(0, 36, 0, 1) 100%);
$miniSeatsBackground: #000000;
$sideMenuBackground: #242424;
$quickFilterButtonBackground: #222;
$quickFilterButtonActiveBackground: black;
$desktopTabBackground: #222;
$desktopTabSelectedBackground: linear-gradient(180deg, $lobbyTrimColor 0px, darken($lobbyTrimColor, 10) 100%);
$desktopTabColor: #EEE;
$defaultAdBarBackground: #f0f0f0 url('../common/images/sidepanel.jpg') center/100%;

// Lobby Tabs
$lobbyTabBarBackground: linear-gradient(180deg, rgba(250, 250, 250, 1) 0%, rgba(220, 220, 220, 1) 100%);
$lobbyTabBarBackgroundActive: linear-gradient(180deg, rgba(150, 150, 150, 1) 0%, rgba(200, 200, 200, 1) 100%);


// Table
$tableFeltColor: #008000;
$tableOuterRailColor: rgb(20, 20, 20);
$defaultAvatarSize: contain;
$tableHighlightTextColor: #ffffff;

// Settings
$settingsHeadingText: #ffffff;
$settingsHeadingBackground: linear-gradient(180deg, #3C3C3C 0%, #232323 100%);
$settingsRowText: #2F3E46;
$settingsRowBackground: linear-gradient(to bottom, #F6F6F6, #ffffff);
$settingsRowHoverBackground: linear-gradient(to bottom, #EDEDED, #DADADA);
$settingsRowSeparator: #E8E8E8;
$settingsBackground: none;

// Modals
$modalOverlayColor: rgba(0, 0, 0, 0.5);
$modalShadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.5);
$modalBackground: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.8) 100%);
$modalTextColor: #ffffff;
$modalBorderColor: #BEBEBE;
$modalSliderBackground: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.65) 99%, rgba(0, 0, 0, 0.65) 100%);
$modalSliderTextColor: #ffffff;
$modalSliderBorderColor: #BEBEBE;

// drop menus
$dropMenuTextColor: #ffffff;
$dropMenuBackground: #333333;
$dropMenuDarkerBackground: #292929;
$dropMenuSeparator: #3c3c3c;

// Card Images
$cardSpriteSheet: url('../common/images/cards/cards60.png');
$cardCustomBackImg: none;
$cardCustomBackSize: 100% 100%;
$cardCustomBackPosition: 0 0;

// Sprites
$iconSprite_128_url: url('../common/images/mobileIcons.png');
$iconSprite_128_size: 782px 34px;
$iconSprite_128_scale: 34px;
$timebankSpriteUrl: url('../common/images/timebankSprite.png');

$componentsSpriteUrl: url('../common/images/componentsSprite.png');
$componentsSpriteWidth: 590px;
$componentsSpriteHeight: 343px;

$checkboxSprite: url('../common/images/checkbox.png');
$checkboxSprite2x: url('../common/images/checkbox2x.png');

// offline
$offlineLogoSize: 80%;

$progressBarBackground: #5d5c57 linear-gradient(180deg, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.55) 1px, rgba(0, 0, 0, 0) 100%);

//
//  Items below here are not casino customisable, do not include in casino.scss
//

// Player
$allIn: #FF0000;
$bet: #FF6600;
$raise: #FF6600;
$call: #CC9900;
$check: #87AD00;
$fold: #008282;
$sitout: #000000;
$awaitingAction: #FF0000;
$currentPlayer: #D7D6C2;


$sliderGrip: radial-gradient(center, 50% 0%, 8% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%) radial-gradient(center, 50% 100%, 12% 50%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%) radial-gradient(center, 0% 50%, 50% 7%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%) radial-gradient(center, 100% 50%, 50% 5%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%) repeating-radial-gradient(center, 50% 50%, 100% 100%, transparent 0%, transparent 3%, rgba(0, 0, 0, 0.1) 3.5%) repeating-radial-gradient(center, 50% 50%, 100% 100%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 6%, rgba(255, 255, 255, 0.1) 7.5%) repeating-radial-gradient(center, 50% 50%, 100% 100%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1.2%, rgba(255, 255, 255, 0.2) 2.2%) radial-gradient(center, 50% 50%, 200% 50%, rgba(230, 230, 230, 1) 5%, rgba(217, 217, 217, 1) 30%, rgba(153, 153, 153, 1) 100%);
