.tournament {
  overflow-x: scroll;
  color: $altTextColor;
  background: white;
  padding-bottom: 20px;

  .small {
    font-size: 80%;
  }
  .large {
    font-size: 140%;
  }
  .nowrap {
    white-space: nowrap;
  }

  .light {
    color: $altTextColorLight;
  }

  .pane {
    label {
      display: block;
    }
    select {
      width: 100%;
    }
    input {
      border: 1px solid #ddd;
    }
  }

  > .pane__left {
    float: left;
    width: 36%;
    min-width: 280px;
    margin-left: 2%;
    margin-top: -25px;
  }

  > .pane__right {
    float: left;
    width: 60%;
    margin-left: 2%;
    padding-top: 10px;
  }

  .pane__header {
    @include selfclear();
    background: $lobbyHeaderBackground;
    color: #eee;
    padding: 1%;
    padding-left: 2.5%;
    padding-bottom: 30px;
    .left {
      width: 70%;
      float: left;
    }
  }


  .pane__details {
    clear: left;
    background: #F5F5F5;
    font-size: 80%;
    padding: 4%;
    padding-top: 20px;
    margin: -10px 5px 0;
    border: 1px solid #ddd;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .kvpair label {
      color: $altTextColorLight;
      text-align: right;
      padding-bottom: 5px;
      padding-right: 10px;
    }
  }

  .pane__prizes {
    @include selfclear();
    > .pane {
      float: left;
      width: 48%;
      min-width: 233px;
      margin-right: 1%;
    }
    img {
      float: left;
    }
    h3 {
      margin-top: 0;
    }
    thead tr th:nth-child(2) {
      width: 206px;
    }

    .pane__right, .pane__summary, .pane__satellites {
      border: 1px solid #ddd;
    }

    .pane__right {
      overflow-y: scroll;
      height: 181px;
    }

    .pane__summary {
      padding: 5px;
      img {
        position: relative;
        bottom: 5px;
        right: 5px;
      }
    }

    .pane__satellites {
      margin-top: 10px;
      padding: 5px;
      img {
        float: left;
      }
      div {
        position: relative;
        top: 5px;
        left: 5px;
      }
      .link {
        display: block;
        margin-top: 15px;
      }
    }

    // .scrollable tbody
    //   height: 180px
  }

  .pane__lists {
    clear: right;
    margin-top: 20px;
    > .pane {
      float: left;
      width: 48%;
      min-width: 233px;
      height: 270px;
      overflow-y: scroll;
      border: 1px solid #ddd;
      margin-right: 1%;
    }
  }
}

.kvpair {
  label {
    float: left;
    width: 49%;
    color: $altTextColorLight;
  }
}

.kvpair.break {
  margin-bottom: 10px;
}

.kvline {
  font-weight: bold;
  margin-bottom: 10px;
  label {
    display: block;
    font-weight: normal;
    color: $altTextColorLight;
  }
}

.tournament__back__button {
  margin: 5px 0;
  .back__button {
    cursor: pointer;

    &:hover {
      color: #ddd;
    }
  }
}

.tournament__title {
  padding-top: 1%;
  font-size: 120%;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
}

.tournament__subtitle {
  font-weight: normal;
  font-size: 85%;
  margin-top: 0;
  color: #aaa;
}

.tournament__overview {
  border-radius: 12px;
  border: 1px solid #ddd;
  box-shadow: 4px 4px 5px rgba(50, 50, 50, 0.25);
  padding: 4%;
  background: white;
  position: relative; // hack to place box shadow over top of details

  .kvline {
    float: left;
    width: 49%;
    label {
      font-size: 90%;
    }
    &.startsIn {
      float: right;
    }
  }
  .kvpair {
    clear: left;
    font-size: 75%;
    margin-bottom: 5px;
    &.float {
      clear: none;
      width: 50%;
      float: left;
      line-height: 1.2em;
      min-height: 1.2em;
      label {
        width: auto;
        margin-right: 3px;
      }
    }
  }

  .kvline.rightAlign {
    text-align: right;
    label, span, a, .link {
      margin-right: 15%;
    }
  }

  .kvpair.rightAlign {
    label {
      text-align: right;
      width: 44%;
      margin-right: 5%;
    }
  }

  .kvpair.float.rightAlign {
    text-align: right;
    padding-right: 8%;
    width: 42%;
    label {
      float: none;
      display: inline;
    }
    &.lvlStruct {
      clear: left;
    }
  }

  // overview sub panes
  > .pane {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 10px;
    clear: both;
  }


  .pane__register {
    @include selfclear();
    label {
      margin-left: 10px; // todo: this is a weird alignment hack
    }
    button {
      float: right;
      margin: 4px 2px;
    }
  }

  .pane__winner {
    float: right;
    width: 50%;
    text-align: center;
    .avatar {
      float: none;
      width: 60px;
      height: 60px;
      margin: 0 auto;
      border: 4px solid #CCC;
      border-radius: 4px;
      background: $defaultAvatar;
      background-size: contain;
    }
  }

  .pane__winner.notVisible {
    visibility: hidden;
  }
}

.tournament__status {
  float: left;
  clear: left;
}

// one column (note 2 column styles are also applied for some reason. possibly our weird viewport fix crap)
// @media only screen and (max-width : 640px)

// 2 columns
@media screen and (max-width: 838px) {
  .tournament {
    > .pane__left {
      width: 46%; // margin is 2%
      min-width: 270px;
      font-size: 90%;
    }
    > .pane__right {
      width: 46%; // margin is 2%
    }
    .pane__prizes, .pane__lists {
      > .pane {
        margin-bottom: 10px;
        width: 98%; // margin is 2%
        font-size: 90%;
      }
    }
    .pane__tables {
      margin: 1px 0;
    }
    .pane__lists > .pane {
      height: 180px;
    }
  }
}

@media screen and (orientation: landscape) {
  .format-mobile .pane__header .left {
    width: 70%;
  }
}

// note format-mobile
// @media only screen and (max-width : 768px) and (orientation: portrait)
@media screen and (max-width: 640px) and (orientation: portrait) {
  .format-mobile .tournament {
    > .pane {
      width: auto;
    }
  }
  .format-mobile .pane__header {
    padding-bottom: 30px;
  }
  .format-mobile .tournament__status {
    // float: left
    // margin-right: 20px
    font-size: 90%;
    // clear: left
  }
}

.tournamentdetails-close-button {
  margin: 0;
  float: right;
  clear: right;
  background: #940000;
}

.modal .tournamentLobbyModal {
  max-width: 1140px;
  button, .button {
    font-size: 90%;
  }
}

// modals have padding on the bottom by default
// becuase tournament preview is while background you can see black gap
.modal {
  .box.tournamentLobbyModal {
    .content {
      padding-bottom: 0;
    }
  }
}


// .format-mobile .tournamentdetails-close-button
//   display: none

.tournament-accolade {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 70px;
  height: 70px;
  display: inline-block;
  float: left;
}

.modal-close-button {
  width: 22px;
  height: 22px;
  background: #222;
  border-radius: 11px;
  position: absolute;
  top: 20px;
  right: 2px;
  text-align: center;
}

@media all and (orientation: landscape) {
  .modal-close-button {
    top: 5px;
  }
}
