.box.pref-seat-modal {
  .miniSeats {
    display: block;
    position: relative;
    width: 265px;
    height: 135px;
    margin: 6px auto 0;

    span {
      border-radius: 15px;
      width: 20px;
      height: 20px;
      margin-left: -15px;
      margin-top: -15px;
      border: 5px solid white;
      cursor: pointer;
      &:active, &.currentPref {
        background: white;
        background-image: radial-gradient(circle closest-side at center, #FFF 75%, #000 100%);
      }
    }
  }
}

.preferred-seating-container {
  position: relative;
}
.preferred-seating-table-felt {
  font-size: 2px;
  position: absolute;
  margin: 0 auto;
  width: 100%;
  top: 30%;
}

@media all and (min-width: 422px) {
  .box.pref-seat-modal {
    .preferred-seating-table-felt {
      font-size: 3px;
      top: 23%;
    }

    .miniSeats {
      width: 368px;
      height: 183px;
    }
  }
}

@media all and (min-width: 522px) {
  .box.pref-seat-modal {
    .preferred-seating-table-felt {
      font-size: 4px;
      top: 19%;
    }

    .miniSeats {
      width: 470px;
      height: 225px;
    }
  }
}
