.statistics-modal {
  width: 90%;
}

.stat-label {
  color: #bbb;
  padding: 10px 0 0;
}

.stat-span {
  color: white;
}
