.chatBubble {
  transition: opacity 0.5s ease;
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  background: linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.8) 100%),
              radial-gradient(center, ellipse farthest-corner, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 2%, rgba(255, 255, 255, 0.6) 68%, rgba(255, 255, 255, 0.1) 69%, rgba(255, 255, 255, 0.1) 100%) 0px -20px no-repeat;
  color: black;
  border-top-left-radius: 2em;
  border-top-right-radius: 2em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 2em;
  width: 16%;
  padding: 1%;
  margin: -14.5% 0 0 -9%;
  font-size: 70%;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
  z-index: 120;
  display: block;
  opacity: 0;
}

.chatBubble-emoticon {
  margin: -6% 0;
  position: relative;
  left: 29%;
}

.chatBubble-visible {
  opacity: 1;
}

.chatBubble:empty {
  display: none;
}
