.tableList {
  background: white;
}

ul.gameList {
  li {
    width: 100%;
    cursor: pointer;
    position: relative;
    height: 30px;
    background: $lobbyListRowColor;
    border-top: 1px solid;
    border-color: #e8e8e8;
    color: $lobbyListFontColor;
    padding: 5px 0;
    box-sizing: content-box;
  }

  li:nth-child(even) {
    background: $lobbyListRowAltColor;
    color: $lobbyListFontAltColor;
  }

  li.selected, li:active {
    background: $lobbyListRowHoverColor;
  }

  li.playing, li.registered {
    background: $lobbyListRowPlayingColor;
  }

  li.mtt {
    .name, .stakes {
      padding-left: 100px;
    }
    .starts {
      display: block;
    }
  }

  .name {
    font-size: 90%;
    text-align: left;
    padding: 0 75px 0 60px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .stakes {
    font-size: 65%;
    padding-left: 60px;
    float: left;
  }

  .state {
    font-size: 65%;
    padding-left: 3px;
    float: left;
    color: #6F797E;
    &.registering {
      color: #1C752C;
    }
  }

  .limitType {
    position: absolute;
    font-size: 90%;
    margin-top: -18px;
    text-align: right;
    right: 30px;
    width: 160px;
    display: none;
    &.short {
      display: inline;
      width: 65px;
    }
  }

  .players {
    position: absolute;
    margin-top: -1px;
    text-align: right;
    font-size: 65%;
    right: 30px;
  }

  .miniSeats {
    position: absolute;
    left: 0px;
    width: 60px;
    height: 30px;
    display: inline-block;
  }

  .knob {
    padding-left: 15px;
    position: absolute;
    left: 0px;
    width: 60px;
    height: 30px;
    display: inline-block;
  }

  .starts {
    display: none; // switched on above by li.mtt
    padding-left: 15px;
    position: absolute;
    left: 0px;
    width: 100px;
    height: 30px;
    display: inline-block;
    overflow: hidden;
    .label {
      display: block;
      font-size: 60%;
    }
    .value {
      display: block;
      font-size: 80%;
      color: black;
    }
  }
}

.loadingOverlay {
  position: fixed;
  top: 93px; // grr this is 1px too low on mobile
  top: 92px; // grr this is 1px too high on (desktop?)
  padding-top: 15%;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-style: italic;
  font-size: 150%;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #EEE; //#999
}

.filterMessage, .errorMessage {
  display: none;
  position: relative;
  width: 80%;
  margin: 10% auto;
  text-align: center;
  color: #555;
  line-height: 1.6em;
  a {
    cursor: pointer;
    color: black;
    font-weight: bold;
  }

  &.noGames {
    display: table-cell;
  }
}

.pin {
  position: absolute;
  margin-top: -20px;
  right: 0px;
  width: 29px;
  height: 37px;
  background-image: $iconSprite_128_url;
  background-size: $iconSprite_128_size;
  background-position: -346px -3px; // -($iconSprite_128_scale * 10) -3px
  opacity: 0.3;
  cursor: pointer;
  background-repeat: no-repeat;
}

.pinned:last-child {
  border-bottom: 2px solid #DDD;
}
.pinned .pin {
  opacity: 1;
}


@media all and (min-width: 450px) {
  ul.gameList {
    .limitType {
      display: inline;
      &.short {
        display: none;
      }
    }
    .name {
      padding: 0 130px 0 60px;
    }
  }
}
